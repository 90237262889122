import { createSelector } from 'reselect'

import {
	areAdditionalDocumentFieldsRequired,
	areDocumentDetailsRequired,
	isAdditionalGuestSignatureRequired,
	isAddressRequired,
	isBirthDateRequired,
	isBirthplaceAndDateRequired,
	isDocumentSupportNumberRequired,
	isFireSafertyRequired,
	isGenderRequired,
	isGuestResidencyProvinceRequired,
	isNextDestinationRequired, isPostalCodeRequired,
	isSignatureRequired,
	isVisitedCountryRequired,
} from 'Src/utils'

import { selectReservation, selectReservationState } from '../reservation.selectors'

// MAIN GUEST

const selectMainGuest = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.mainGuest,
)

const selectMainGuestCustomer = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.mainGuestCustomer,
)

const selectMainGuestIsEmailFieldVisible = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.isMainGuestEmailVisible,
)

const selectMainGuestDocument = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.mainGuestDocument,
)

const selectMainGuestAddress = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.mainGuestAddress,
)

const selectMainGuestCurrentCreditCard = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.mainGuestCreditCard,
)

const selectMainGuestNewCreditCard = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.mainGuestNewCreditCard,
)

const selectCreditCards = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.creditCards,
)

const selectMainGuestDocumentDetailsRequired = createSelector(
	[
		selectReservation,
		selectMainGuestCustomer,
		selectMainGuestAddress,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		{ nationalityCode },
		{ countryCode: residenceCountryCode },
	) => areDocumentDetailsRequired(
		propertyCountryCode,
		nationalityCode,
		residenceCountryCode,
	),
)

const selectMainGuestDocumentSupportNumberRequired = createSelector(
	[
		selectReservation,
		selectMainGuestCustomer,
		selectMainGuestDocument,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		{ nationalityCode },
		guestDocument,
	) => {
		const { type: documentType } = guestDocument || {}
		return isDocumentSupportNumberRequired(propertyCountryCode, nationalityCode, documentType)
	},
)

const selectMainGuestVisitedCountryRequired = createSelector(
	[
		selectReservation,
		selectMainGuestAddress,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		{ countryCode: residenceCountryCode },
	) => isVisitedCountryRequired(propertyCountryCode, residenceCountryCode),
)

const selectMainGuestGenderRequired = createSelector(
	[
		selectReservation,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
	) => isGenderRequired(propertyCountryCode),
)

const selectMainGuestSignatureRequired = createSelector(
	[
		selectReservation,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
	) => isSignatureRequired(propertyCountryCode),
)

const selectNextDestinationRequired = createSelector(
	[
		selectReservation,
		selectMainGuestCustomer,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		{ nationalityCode },
	) => isNextDestinationRequired(propertyCountryCode, nationalityCode),
)

const selectFireSafetyRequired = createSelector(
	[
		selectReservation,
	],
	(
		{ property },
	) => {
		const propertyKey = property?.key ?? ''
		return isFireSafertyRequired(propertyKey)
	},
)

const selectMainGuestBirthDateRequired = createSelector(
	[
		selectReservation,
		selectMainGuestCustomer,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		{ nationalityCode },
	) => isBirthDateRequired(propertyCountryCode, nationalityCode),
)

// ADDITIONAL GUESTS

const selectAdditionalGuests = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.additionalGuests,
)

const selectCurrentAdditionalGuest = createSelector(
	[selectReservationState],
	(reservationState) => reservationState.currentAdditionalGuest,
)

const selectAdditionalGuestGenderRequired = createSelector(
	[
		selectReservation,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
	) => isGenderRequired(propertyCountryCode),
)

const selectCurrentAdditionalGuestDocumentDetailsRequired = createSelector(
	[
		selectReservation,
		selectCurrentAdditionalGuest,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		guest,
	) => {
		const { nationalityCode } = guest?.customer || {}
		const { countryCode: residenceCountryCode } = guest?.address || {}

		return areDocumentDetailsRequired(
			propertyCountryCode,
			nationalityCode,
			residenceCountryCode,
		)
	},
)

const selectCurrentAdditionalGuestDocumentSupportNumberRequired = createSelector(
	[
		selectReservation,
		selectCurrentAdditionalGuest,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		guest,
	) => {
		const { nationalityCode } = guest?.customer || {}
		const { type: documentType } = guest?.document || {}

		return isDocumentSupportNumberRequired(propertyCountryCode, nationalityCode, documentType)
	},
)

const selectGuestNextDestinationRequired = createSelector(
	[
		selectReservation,
		selectCurrentAdditionalGuest,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		guest,
	) => {
		const { nationalityCode } = guest?.customer || {}
		return isNextDestinationRequired(propertyCountryCode, nationalityCode)
	},
)

const selectCurrentAdditionalGuestVisitedCountryRequired = createSelector(
	[
		selectReservation,
		selectCurrentAdditionalGuest,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		guest,
	) => {
		const { countryCode: residenceCountryCode } = guest?.address || {}

		return isVisitedCountryRequired(propertyCountryCode, residenceCountryCode)
	},
)

const selectCurrentAdditionalGuestSignatureRequired = createSelector(
	[
		selectReservation,
		selectCurrentAdditionalGuest,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		guest,
	) => {
		const { birthDate } = guest?.customer || {}

		return isAdditionalGuestSignatureRequired(propertyCountryCode, birthDate)
	},
)

const selectAllAdditionalGuestDetailsAreComplete = createSelector(
	[selectAdditionalGuests],
	(additionalGuests) => !additionalGuests.find((guest) => !guest.guestDetailsAreComplete),
)

const selectBirthPlaceAndDateRequired = createSelector(
	[
		selectReservation,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
	) => isBirthplaceAndDateRequired(propertyCountryCode),
)

const selectAdditionalGuestBirthDateRequired = createSelector(
	[
		selectReservation,
		selectCurrentAdditionalGuest,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
		guest,
	) => {
		const { nationalityCode } = guest?.customer || {}

		return isBirthDateRequired(propertyCountryCode, nationalityCode)
	},
)

const selectAddressRequired = createSelector(
	[
		selectReservation,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
	) => isAddressRequired(propertyCountryCode),
)

const selectPostalCodeRequired = createSelector(
	[
		selectReservation,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
	) => isPostalCodeRequired(propertyCountryCode),
)

const selectMainGuestResidencyProvinceRequired = createSelector(
	[selectMainGuestAddress],
	({ countryCode }) => isGuestResidencyProvinceRequired(countryCode),
)

const selectAdditionalDocumentFieldsRequired = createSelector(
	[
		selectReservation,
	],
	(
		{ property: { countryCode: propertyCountryCode } },
	) => areAdditionalDocumentFieldsRequired(propertyCountryCode),
)

const selectAllGuestsCustomers = createSelector(
	[selectReservationState],
	(reservationState) => {
		const { mainGuestCustomer, additionalGuests } = reservationState
		const additionalGuestsCustomers = additionalGuests.map((guest) => guest.customer)

		return [mainGuestCustomer, ...additionalGuestsCustomers]
	},
)

export {
	selectMainGuest,
	selectMainGuestCustomer,
	selectMainGuestIsEmailFieldVisible,
	selectMainGuestDocument,
	selectMainGuestAddress,
	selectMainGuestCurrentCreditCard,
	selectMainGuestNewCreditCard,
	selectCreditCards,
	selectMainGuestDocumentDetailsRequired,
	selectMainGuestVisitedCountryRequired,
	selectMainGuestGenderRequired,
	selectMainGuestSignatureRequired,
	selectMainGuestDocumentSupportNumberRequired,
	selectCurrentAdditionalGuestSignatureRequired,
	selectNextDestinationRequired,
	selectFireSafetyRequired,
	selectAdditionalGuests,
	selectCurrentAdditionalGuest,
	selectGuestNextDestinationRequired,
	selectCurrentAdditionalGuestDocumentDetailsRequired,
	selectCurrentAdditionalGuestDocumentSupportNumberRequired,
	selectCurrentAdditionalGuestVisitedCountryRequired,
	selectAllAdditionalGuestDetailsAreComplete,
	selectBirthPlaceAndDateRequired,
	selectAddressRequired,
	selectPostalCodeRequired,
	selectAdditionalDocumentFieldsRequired,
	selectAdditionalGuestGenderRequired,
	selectAllGuestsCustomers,
	selectMainGuestResidencyProvinceRequired,
	selectMainGuestBirthDateRequired,
	selectAdditionalGuestBirthDateRequired,
}
