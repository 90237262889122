import { getData as getCountries } from 'country-list'
import { isBefore } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import i18next from 'i18next'
import parkingEntranceImgTallinnTelliskivi from 'Assets/parking-indoor/tallinn-telliskivi-entrance.webp'
import parkingBuildingImgTallinnTelliskivi from 'Assets/parking-indoor/tallinn-telliskivi-building.webp'
import parkingHeaderImgTallinnTelliskivi from 'Assets/parking-indoor/tallinn-telliskivi-building-large.webp'
import productImgGym from 'Assets/products/product-gym.webp'
import productImgBreakfast from 'Assets/products/product-breakfast.webp'
import productImgCleaningMidStay from 'Assets/products/product-cleaning-mid-stay.webp'
import productSauna from 'Assets/products/product-sauna.webp'
import productMealKit from 'Assets/products/product-mealkit.webp'
import productCleankitchenMealkit from 'Assets/cleankitchen-mealkit/chicken_noodle.webp'
import productStockedFridge from 'Assets/stocked-fridge/stocked-fridge-main.webp'
import visaImg from 'Assets/payment-icons/visa.svg'
import mastercardImg from 'Assets/payment-icons/mastercard.svg'
import amexImg from 'Assets/payment-icons/amex.svg'
import discoverImg from 'Assets/payment-icons/discover.svg'
import dinersImg from 'Assets/payment-icons/diners.svg'
import jcbImg from 'Assets/payment-icons/jcb.svg'
import unionPayImg from 'Assets/payment-icons/unionpay.svg'

import carouselImg01TallinnTelliskivi
	from '@/assets/check-in-start-view-carousel-img/carousel-img-01-tallinn-telliskivi.webp'
import carouselImg01TallinnAmbassador
	from 'Assets/check-in-start-view-carousel-img/carousel-img-01-tallinn-ambassador.webp'
import carouselImg01TallinnAvangard from 'Assets/check-in-start-view-carousel-img/carousel-img-01-tallinn-avangard.webp'
import carouselImg01TartuDuoLofts from 'Assets/check-in-start-view-carousel-img/carousel-img-01-tartu-duo-lofts.webp'
import carouselImg01FlorencePonteVecchio
	from 'Assets/check-in-start-view-carousel-img/carousel-img-01-florence-ponte-vecchio.webp'
import carouselImg01HelsinkiVuorikatu
	from 'Assets/check-in-start-view-carousel-img/carousel-img-01-helsinki-vuorikatu.webp'
import carouselImg01HelsinkiKru4 from 'Assets/check-in-start-view-carousel-img/carousel-img-01-helsinki-kru4.webp'
import carouselImg01HelsinkiPun4 from 'Assets/check-in-start-view-carousel-img/carousel-img-01-helsinki-pun4.webp'
import carouselImg01MadridChueca from 'Assets/check-in-start-view-carousel-img/carousel-img-01-madrid-chueca.webp'
import carouselImg02MadridChueca from 'Assets/check-in-start-view-carousel-img/carousel-img-02-madrid-chueca.webp'
import carouselImg03MadridChueca from 'Assets/check-in-start-view-carousel-img/carousel-img-03-madrid-chueca.webp'
import carouselImg01LondonGt24 from 'Assets/check-in-start-view-carousel-img/carousel-img-01-london-gt24.webp'
import carouselImg01LondonDev14 from 'Assets/check-in-start-view-carousel-img/carousel-img-01-london-dev14.webp'
import carouselImg01LondonCh73 from 'Assets/check-in-start-view-carousel-img/carousel-img-01-london-ch73.webp'
import carouselImg01TurkuEer12 from 'Assets/buildings/turku-eer12/carousel/01.webp'
import carouselImg01HelsinkiKas40 from 'Assets/buildings/helsinki-kas40/carousel/01.webp'
import carouselImg01LondonCh65 from 'Assets/buildings/london-ch65/carousel/01.webp'
import carouselImg03 from 'Assets/check-in-start-view-carousel-img/carousel-img-03.webp'
import helsinkiVu12BuildingImage from '@/assets/buildings/helsinki-vu12/building.webp'
import helsinkiVu12CodeImage from '@/assets/buildings/helsinki-vu12/code.webp'
import helsinkiVu12RoomImage from '@/assets/buildings/helsinki-vu12/room.webp'
import londonCs43CodeImage from '@/assets/buildings/london-cs43/code.webp'
import londonCs43RoomImage from '@/assets/buildings/london-cs43/room.webp'
import londonCs43StreetImage from '@/assets/buildings/london-cs43/street.webp'
import madridFldl18BuildingImage from '@/assets/buildings/madrid-fldl18/building.webp'
import madridFldl18CodeImage from '@/assets/buildings/madrid-fldl18/code.webp'
import madridFldl18RoomImage from '@/assets/buildings/madrid-fldl18/room.webp'
import { CustomerDocumentApi, TravelReasonApi } from 'Types/api.types'
import { StepsType } from 'Types/app-state.types'
import { DisplayableProduct } from 'Src/types/reservation-state.types'

// Carousel common images
import carouselRemoteAccessImage from '@/assets/carousel-remote-access.webp'
import carouselCodeAccessImage from '@/assets/carousel-code-access.webp'
import carouselKeyAccessImage from '@/assets/carousel-key-access.webp'
import carouselKeycardAccessImage from '@/assets/carousel-keycard-access.webp'

// Oslo KIR30
import osloKir30CarouselImage01 from '@/assets/buildings/oslo-kir30/carousel/01.webp'
import osloKir30CarouselImage02 from '@/assets/buildings/oslo-kir30/carousel/02.webp'
import osloKir30CarouselImage03 from '@/assets/buildings/oslo-kir30/carousel/03.webp'

// Oslo GRO16
import osloGro16CarouselImage01 from '@/assets/buildings/oslo-gro16/carousel/01.webp'

// Bonn BNG7
import bonnBng7CarouselImage01 from '@/assets/buildings/bonn-bng7/carousel/01.webp'

// Freising MAI36
import freisingMai36CarouselImage01 from '@/assets/buildings/freising-mai36/carousel/01.webp'

// Lubeck LIN2
import lubeckLin2CarouselImage01 from '@/assets/buildings/lubeck-lin2/carousel/01.webp'

// Amsterdam MID2
import amsterdamMid2CarouselImage01 from '@/assets/buildings/amsterdam-mid2/carousel/01.webp'

// Berlin KOP92
import berlinKop92CarouselImage01 from '@/assets/buildings/berlin-kop92/carousel/01.webp'

// Parnu ESP10
import parnuEsp10CarouselImage01 from '@/assets/buildings/parnu-esp10/carousel/01.webp'

// Milan DD8
import carouselImg01MilanDd8 from 'Assets/check-in-start-view-carousel-img/carousel-img-01-milan-dd8.webp'

// Amsterdam, PAP4
import amsterdamPap4CarouselImage01 from '@/assets/buildings/amsterdam-pap4/carousel/01.webp'

// London, BEA7
import londonBea7CarouselImage01 from '@/assets/buildings/london-bea7/carousel/01.webp'

// Athens, ZAL4
import athensZal4CarouselImage01 from '@/assets/buildings/athens-zal4/carousel/01.webp'

// Athens, VIS2
import athensVis2CarouselImage01 from '@/assets/buildings/athens-vis2/carousel/01.webp'

// London, WS75
import carouselImg01LondonWs75 from '@/assets/buildings/london-ws75/carousel/01.webp'

import { Environment, getEnvironment, isWhiteLabelDomain } from 'Src/utils/env'

// Copenhagen, TEG31
import copenhagenTeg31CarouselImage01 from '@/assets/buildings/copenhagen-teg31/carousel/01.webp'

// Tallinn, LII34
import tallinnLii34CarouselImage01 from 'Assets/buildings/tallinn-lii34/carousel/01.webp'

// London, HOL31
import londonHol31CarouselImage01 from 'Assets/buildings/london-hol31/carousel/01.webp'

// London, HOL128
import londonHol128CarouselImage01 from 'Assets/buildings/london-hol128/carousel/01.webp'

// Munich, HOH9
import munichHoh9CarouselImage01 from 'Assets/buildings/munich-hoh9/carousel/01.webp'

// Munich, BAA1
import munichBaa1CarouselImage01 from 'Assets/buildings/munich-baa1/carousel/01.webp'

// Munich, FAL22
import munichFal22CarouselImage01 from 'Assets/buildings/munich-fal22/carousel/01.webp'

export const enum PropertyAccessType {
	PhysicalKey = 'physical-key',
	Code = 'code',
	Remote = 'remote'
}

export enum PropertyKey {
	CHUECA = 'madrid-chueca',
	FLDL18 = 'madrid-fldl18',
	LondonCs43 = 'london-cs43',
	LondonDev14 = 'london-dev14',
	LondonGt24 = 'london-gt24',
	LondonCh73 = 'london-ch73',
	FlorenceGui18 = 'florence-gui18',
	LAI29 = 'tartu-lai29',
	NAR40 = 'tallinn-nar40',
	REI8 = 'tallinn-rei8',
	TELLISKIVI = 'bob-w-telliskivi',
	OsloGro16 = 'oslo-gro16',
	OsloKir30 = 'oslo-kir30',
	VU12 = 'helsinki-vu12',
	HelsinkiVi05 = 'bob-w-vuorikatu',
	HelsinkiKru4 = 'helsinki-kru4',
	HelsinkiPun4 = 'helsinki-pun4',
	MilanDd8 = 'milano-priv8',
	BonnBng7 = 'bonn-bng7',
	FreisingMai36 = 'freising-mai36',
	LubeckLin2 = 'lubeck-lin2',
	AmsterdamMid2 = 'amsterdam-mid2',
	BerlinKop92 = 'berlin-kop92',
	ParnuEsp10 = 'parnu-esp10',
	AmsterdamPap4 = 'amsterdam-pap4',
	LondonBea7 = 'london-bea7',
	AthensZal4 = 'athens-zal4',
	LondonPhi27 = 'london-phi27',
	LondonWar60 = 'london-war60',
	LondonCh65 = 'london-ch65',
	AthensVis2 = 'athens-vis2',
	CopenhagenTeg31 = 'copenhagen-teg31',
	TurkuEer12 = 'turku-eer12',
	LondonWs75 = 'london-ws75',
	TallinnLii34= 'tallinn-lii34',
	HelsinkiKas40= 'helsinki-kas40',
	LondonHol31 = 'london-hol31',
	LondonHol128 = 'london-hol128',
	MunichHoh9 = 'munich-hoh9',
	MunichBaa1 = 'munich-baa1',
	MunichFal22 = 'munich-fal22',
}

export const propertyKeysMap : Record<string, PropertyKey> = {
	'bob-w-vuorikatu': PropertyKey.HelsinkiVi05,
	'helsinki-vu12': PropertyKey.VU12,
	'helsinki-kru4': PropertyKey.HelsinkiKru4,
	'helsinki-pun4': PropertyKey.HelsinkiPun4,
	'bob-w-telliskivi': PropertyKey.TELLISKIVI,
	'tartu-lai29': PropertyKey.LAI29,
	'tallinn-nar40': PropertyKey.NAR40,
	'tallinn-rei8': PropertyKey.REI8,
	'oslo-gro16': PropertyKey.OsloGro16,
	'oslo-kir30': PropertyKey.OsloKir30,
	'madrid-chueca': PropertyKey.CHUECA,
	'london-cs43': PropertyKey.LondonCs43,
	'london-dev14': PropertyKey.LondonDev14,
	'london-ch65': PropertyKey.LondonCh65,
	'madrid-fldl18': PropertyKey.FLDL18,
	'london-gt24': PropertyKey.LondonGt24,
	'london-ch73': PropertyKey.LondonCh73,
	'florence-gui18': PropertyKey.FlorenceGui18,
	'milano-priv8': PropertyKey.MilanDd8,
	'bonn-bng7': PropertyKey.BonnBng7,
	'freising-mai36': PropertyKey.FreisingMai36,
	'lubeck-lin2': PropertyKey.LubeckLin2,
	'amsterdam-mid2': PropertyKey.AmsterdamMid2,
	'berlin-kop92': PropertyKey.BerlinKop92,
	'parnu-esp10': PropertyKey.ParnuEsp10,
	'amsterdam-pap4': PropertyKey.AmsterdamPap4,
	'london-bea7': PropertyKey.LondonBea7,
	'athens-zal4': PropertyKey.AthensZal4,
	'london-phi27': PropertyKey.LondonPhi27,
	'london-war60': PropertyKey.LondonWar60,
	'athens-vis2': PropertyKey.AthensVis2,
	'copenhagen-teg31': PropertyKey.CopenhagenTeg31,
	'turku-eer12': PropertyKey.TurkuEer12,
	'london-ws75': PropertyKey.LondonWs75,
	'tallinn-lii34': PropertyKey.TallinnLii34,
	'helsinki-kas40': PropertyKey.HelsinkiKas40,
	'london-hol31': PropertyKey.LondonHol31,
	'london-hol128': PropertyKey.LondonHol128,
	'munich-hoh9': PropertyKey.MunichHoh9,
	'munich-baa1': PropertyKey.MunichBaa1,
	'munich-fal22': PropertyKey.MunichFal22,
}

export const enum Flow {
	CHECK_IN,
	GUEST_AREA
}

export const houseRulesUrl = 'https://bobw.co/faq'
export const fireSafetyRulesUrl = 'https://bobw.co/fire-safety-rules-bobw-gamle-oslo'
export const spainMinorAge = 14

export function getTermsUrl() : string {
	const isWhiteLabel = isWhiteLabelDomain()

	if (isWhiteLabel) {
		return 'https://www.notahotel.co/terms-and-conditions'
	}

	return 'https://bobw.co/terms'
}

export function getPrivacyUrl() : string {
	const isWhiteLabel = isWhiteLabelDomain()

	if (isWhiteLabel) {
		return 'https://www.notahotel.co/privacy-policy'
	}

	return 'https://bobw.co/privacy'
}

export const getConfig = () => {
	const currentEnv = getEnvironment()
	const IS_PRODUCTION = currentEnv === Environment.Production
	const IS_DEVELOPMENT = currentEnv === Environment.Development || currentEnv === Environment.Dukenukem

	// Keys of products that we are currently using (core-api may return products that are not yet used in the UI)
	enum ACTIVE_PRODUCT_KEYS {
		gym = 'gym',
		babyCrib = 'baby-crib',
		parking = 'parking-indoor',
		breakfast = 'breakfast',
		breakfastComplimentary = 'breakfast-complimentary',
		parkingComplimentary = 'parking-indoor-complimentary',
		cleaningMidStay = 'cleaning-mid-stay',
		sauna = 'sauna-kit',
		mealkit = 'mealkit-platform-pro',
		// In first iteration of clean kitchen mealkit we are not checking for availability of individual dishes
		// so only one of these represents the product in the UI,
		// namely 'meal-kit-clean-kitchen-vegan-dan-dan-noodles-2'
		mealkitCleanKitchenWok2 = 'meal-kit-clean-kitchen-chicken-wok-2',
		mealkitCleanKitchenWok4 = 'meal-kit-clean-kitchen-chicken-wok-4',
		mealkitCleanKitchenNoodles2 = 'meal-kit-clean-kitchen-vegan-dan-dan-noodles-2',
		mealkitCleanKitchenNoodles4 = 'meal-kit-clean-kitchen-vegan-dan-dan-noodles-4',

		stockedFridge = 'stocked-fridge',
		propertyUnitFreeUpgrade = 'property-unit-category-upgrade-free',
		propertyUnitPaidUpgrade = 'property-unit-category-upgrade',
	}

	enum PRODUCT_CHARGE_METHODS {
		perPersonPerTimeUnit = 'per-person-per-time-unit',
		perTimeUnit = 'per-time-unit',
		once = 'once',
		multiple = 'multiple',
	}

	const checkIsDisabled = (
		reservationStartDate: string,
		propertyCountryCode: string,
	) => propertyCountryCode === 'EE'
		&& isBefore(
			new Date(reservationStartDate),
			zonedTimeToUtc('2021-04-15 00:00:00', 'Europe/Tallinn'),
		)

	const DISPLAYABLE_PRODUCTS_DETAILS: DisplayableProduct[] = [
		{
			productKey: ACTIVE_PRODUCT_KEYS.propertyUnitFreeUpgrade,
			title: i18next.t('Free Upgrade'),
			description: {
				default: i18next.t('Free upgrade'),
			},
			isUpgradeableProduct: true,
			requiresAvailability: true,
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.propertyUnitPaidUpgrade,
			title: i18next.t('Paid Upgrade'),
			description: {
				default: i18next.t('Paid upgrade'),
			},
			isUpgradeableProduct: true,
			requiresAvailability: true,
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.breakfast,
			title: i18next.t('Breakfast in local cafes'),
			description: { default: i18next.t('Enjoy breakfast at Bob’s fave neighborhood cafes. All cafes are at a distance of a few hundred meters.') },
			addButtonText: i18next.t('View cafes'),
			image: productImgBreakfast,
			opensInSeparateView: true,
			checkIsDisabled,
			inProductsList: true,

			notificationInGuestArea: {
				title: i18next.t('Breakfast Pass'),
				description: i18next.t('Show this to the staff at the cafe. Enjoy!'),
			},

			notificationInReservationDetails: {
				title: i18next.t('Breakfast Pass'),
				description: i18next.t('Show this to the staff at the cafe. Enjoy!'),
				opensInSeparateView: true,
			},
		},
		{
			// One of the mealkit cleankitchen product keys is used to represent
			//  the product in UI and check availability
			// The others (subProductKeys) are just used for price information.
			// :todo In later iterations of this product,
			// when the availability of individual mealkits is checked, should
			//  find a way to merge several keys in one product
			productKey: ACTIVE_PRODUCT_KEYS.mealkitCleanKitchenNoodles2,
			subProductKeys: [
				'meal-kit-clean-kitchen-chicken-wok-2',
				'meal-kit-clean-kitchen-chicken-wok-4',
				'meal-kit-clean-kitchen-vegan-dan-dan-noodles-2',
				'meal-kit-clean-kitchen-vegan-dan-dan-noodles-4',
			],
			title: i18next.t('Fresh Meal Kit'),
			description: {
				/* eslint-disable max-len */
				default: i18next.t('Share a restaurant-worth meal with a special someone. Get all the pre-portioned ingredients delivered to your apartment.'),
			},
			image: productCleankitchenMealkit,
			addButtonText: i18next.t('Learn more'),
			opensInSeparateView: true,
			inProductsList: true,
			notificationInGuestArea: null,
			notificationInReservationDetails: {
				opensInSeparateView: false,
			},
			requiresAvailability: true,
			skipPayment: true,
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.stockedFridge,
			title: i18next.t('Stocked Fridge with Wolt'),
			description: {
				/* eslint-disable max-len */
				default: i18next.t('Have local groceries waiting when you arrive to make healthy breakfast and snacks.'),
			},
			image: productStockedFridge,
			addButtonText: i18next.t('Learn more'),
			opensInSeparateView: true,
			inProductsList: true,
			notificationInGuestArea: null,
			notificationInReservationDetails: {
				opensInSeparateView: false,
			},
			requiresAvailability: true,
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.gym,
			title: i18next.t('Stay healthy at the local gym'),
			description: {
				default: i18next.t('Bob W likes to stay healthy. Enjoy complimentary gym access during your whole stay. More information is available in the apartment.'),
			},
			image: productImgGym,
			opensInSeparateView: false,
			checkIsDisabled,
			inProductsList: true,
			notificationInGuestArea: null,
			notificationInReservationDetails: {
				opensInSeparateView: false,
			},
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.sauna,
			title: i18next.t('Sauna Kit'),
			description: {
				default: i18next.t('Get the full sauna experience with our special kit, including everything you need to sauna like a Finn.'),
			},
			image: productSauna,
			addButtonText: i18next.t('Learn more'),
			opensInSeparateView: true,
			availableRooms: [
				// production - Vuorikatu
				'72501b22-c4de-4545-a687-acd9010aefc5',
				'ac9983bc-1a52-4c20-a5d1-acd9010aefc5',
				'9e2e34e3-27dc-4f02-8123-acd9010aefc5',
				'3ee551d8-bdc2-4544-aba5-acd9010aefc5',
				// stage - Vuorikatu
				'f707e37b-e03b-40f0-a4b6-abdb008829ed',
				'c08fdce6-f5d8-4d31-b53c-abdb008829ed',
				'acdec699-e6a8-447b-9a8c-abdb008829f1',
				// stage - Telliskivi
				'56efb825-0c2e-4c00-9a41-aa6301144f69',
			],
			inProductsList: true,
			notificationInGuestArea: {
				title: i18next.t('Sauna Kit'),
				description: i18next.t('Enjoy your sauna experience with our special kit, including everything you need to sauna like a Finn.'),
			},
			notificationInReservationDetails: {
				title: i18next.t('Sauna Kit'),
				description: i18next.t('Enjoy your sauna experience with our special kit, including everything you need to sauna like a Finn.'),
				opensInSeparateView: false,
			},
			requiresAvailability: true,
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.mealkit,
			title: i18next.t('Dinner Experience by Michelin-awarded Chef'),
			description: {
				/* eslint-disable max-len */
				default: i18next.t('Share a gourmet 7-course meal with a special someone in the comfort of your Bob. Get all the prepared elements delivered to your apartment. Just assemble and enjoy.'),
			},
			image: productMealKit,
			addButtonText: i18next.t('Learn more'),
			opensInSeparateView: true,
			inProductsList: true,
			notificationInGuestArea: null,
			notificationInReservationDetails: {
				opensInSeparateView: false,
			},
			requiresAvailability: true,
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.parking,
			title: i18next.t('Car Parking'),
			description: {
				default: i18next.t('Traveling with a car? Reserve a dedicated parking spot on-site.'),
			},
			addButtonText: i18next.t('Add'),
			opensInSeparateView: true,
			inProductsList: true,
			notificationInGuestArea: {
				title: i18next.t('Parking'),
				description: i18next.t('Get info about your parking. Where to park, the parking code.'),
			},
			notificationInReservationDetails: {
				title: i18next.t('Parking'),
				description: i18next.t('Get info about your parking. Where to park, the parking code.'),
				opensInSeparateView: true,
			},
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.babyCrib,
			title: i18next.t('Baby Crib & Chair'),
			description: {
				default: i18next.t('For your little one’s happy sleep. The baby crib will be delivered to your apartment before your arrival.'),
			},
			opensInSeparateView: false,
			inProductsList: true,
			notificationInGuestArea: null,
			notificationInReservationDetails: {
				opensInSeparateView: false,
			},
		},
		{
			productKey: ACTIVE_PRODUCT_KEYS.cleaningMidStay,
			image: productImgCleaningMidStay,
			inProductsList: false,
			notificationInGuestArea: {
				title: i18next.t('Schedule your cleaning'),
				description: i18next.t('It is time to schedule your professional apartment cleaning.'),
			},
			notificationInReservationDetails: null,
		},
	]

	DISPLAYABLE_PRODUCTS_DETAILS.push({
		...DISPLAYABLE_PRODUCTS_DETAILS.find((p) => p.productKey === ACTIVE_PRODUCT_KEYS.breakfast),
		isComplimentary: true,
		productKey: ACTIVE_PRODUCT_KEYS.breakfastComplimentary,
	})

	DISPLAYABLE_PRODUCTS_DETAILS.push({
		...DISPLAYABLE_PRODUCTS_DETAILS.find((p) => p.productKey === ACTIVE_PRODUCT_KEYS.parking),
		isComplimentary: true,
		productKey: ACTIVE_PRODUCT_KEYS.parkingComplimentary,
	})

	const STOCKED_FRIDGE_DETAILS = {
		[PropertyKey.TELLISKIVI]: {
			ingredients: [
				i18next.t('3 bananas'),
				i18next.t('Wholegrain rye bread'),
				i18next.t('Gourmet Estonian muesli'),
				i18next.t('Unflavoured soy yogurt'),
				i18next.t('1 litre 2.5% milk'),
				i18next.t('Cheese spread'),
				i18next.t('Sliced Estonian cheese'),
			],
		},
		[PropertyKey.HelsinkiVi05]: {
			ingredients: [
				i18next.t('Rye bread'),
				i18next.t('Butter spread'),
				i18next.t('Cheese'),
				i18next.t('Plain yogurt'),
				i18next.t('Seasonal fruit'),
				i18next.t('Muesli'),
				i18next.t('Oat milk'),
				i18next.t('Orange juice'),
			],
		},
		[PropertyKey.VU12]: {
			ingredients: [
				i18next.t('Rye bread'),
				i18next.t('Butter spread'),
				i18next.t('Cheese'),
				i18next.t('Plain yogurt'),
				i18next.t('Seasonal fruit'),
				i18next.t('Muesli'),
				i18next.t('Oat milk'),
				i18next.t('Orange juice'),
			],
		},
	}

	const PARKING_INDOOR_DETAILS = {
		[PropertyKey.TELLISKIVI]: {
			images: [
				parkingEntranceImgTallinnTelliskivi,
				parkingBuildingImgTallinnTelliskivi,
			],
			headerImage: parkingHeaderImgTallinnTelliskivi,
			instructions: [
				i18next.t('Turn left and enter your door code to the pin pad located on the driver\'s side'),
				i18next.t('Head down to access the car park'),
				i18next.t('Park on any spot between: 23-31'),
				i18next.t('Access building from the car park'),
			],
		},
		// No parking in Vuorikatu
	}

	const PROPERTY_ADDITIONAL_DETAILS = {
		[PropertyKey.TELLISKIVI]: {
			carouselImages: [
				carouselImg01TallinnTelliskivi,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Telliskivi 49',
		},

		[PropertyKey.REI8]: {
			carouselImages: [
				carouselImg01TallinnAmbassador,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Reimani 8',
		},

		[PropertyKey.NAR40]: {
			carouselImages: [
				carouselImg01TallinnAvangard,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Narva mnt 40, 10152 Tallinn',
		},

		[PropertyKey.LAI29]: {
			carouselImages: [
				carouselImg01TartuDuoLofts,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Lai 29, 51005 Tartu',
		},

		[PropertyKey.HelsinkiVi05]: {
			carouselImages: [
				carouselImg01HelsinkiVuorikatu,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Vuorikatu 18',
		},

		[PropertyKey.HelsinkiKru4]: {
			carouselImages: [
				carouselImg01HelsinkiKru4,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Kruunuvuorenkatu 4, 00160 Helsinki',
		},

		[PropertyKey.HelsinkiPun4]: {
			carouselImages: [
				carouselImg01HelsinkiPun4,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Punanotkonkatu 4 D, 00130 Helsinki',
		},

		[PropertyKey.CHUECA]: {
			carouselImages: [
				carouselImg01MadridChueca,
				carouselImg02MadridChueca,
				carouselImg03MadridChueca,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Calle de Fuencarral 45',
		},

		[PropertyKey.OsloGro16]: {
			carouselImages: [
				osloGro16CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Grønlandsleiret 16B, 0190 Oslo',
		},

		[PropertyKey.OsloKir30]: {
			carouselImages: [
				osloKir30CarouselImage01,
				osloKir30CarouselImage02,
				osloKir30CarouselImage03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Kirkegata 30, 0153 Oslo',
		},

		[PropertyKey.LondonGt24]: {
			carouselImages: [
				carouselImg01LondonGt24,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Great Tower Street 24',
		},

		[PropertyKey.VU12]: {
			carouselImages: [
				helsinkiVu12BuildingImage,
				helsinkiVu12CodeImage,
				helsinkiVu12RoomImage,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Vuorikatu 12',
		},

		[PropertyKey.LondonCs43]: {
			carouselImages: [
				londonCs43StreetImage,
				londonCs43CodeImage,
				londonCs43RoomImage,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: '43b Commercial Street',
		},

		[PropertyKey.LondonDev14]: {
			carouselImages: [
				carouselImg01LondonDev14,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: '14 Devonshire Terrace, London W2 3DW',
		},

		[PropertyKey.LondonCh73]: {
			carouselImages: [
				carouselImg01LondonCh73,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: '1 Magdalen Passage, London E1 8NE',
		},

		[PropertyKey.FLDL18]: {
			carouselImages: [
				madridFldl18BuildingImage,
				madridFldl18CodeImage,
				madridFldl18RoomImage,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Calle Fray Luis de León 18',
		},

		[PropertyKey.FlorenceGui18]: {
			carouselImages: [
				carouselImg01FlorencePonteVecchio,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Via de\' Guicciardini, 18, 50125 Florence',
		},

		[PropertyKey.MilanDd8]: {
			carouselImages: [
				carouselImg01MilanDd8,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Via Privata del Don 8, Milan, 20123',
		},

		[PropertyKey.BonnBng7]: {
			carouselImages: [
				bonnBng7CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Bonngasse 7, 53111 Bonn',
		},

		[PropertyKey.FreisingMai36]: {
			carouselImages: [
				freisingMai36CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Mainburger Str. 36, 85356 Freising',
		},

		[PropertyKey.LubeckLin2]: {
			carouselImages: [
				lubeckLin2CarouselImage01,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Lindenplatz 2, 23554 Lübeck',
		},

		[PropertyKey.AmsterdamMid2]: {
			carouselImages: [
				amsterdamMid2CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Middenweg 2, 1097 BM Amsterdam',
		},

		[PropertyKey.BerlinKop92]: {
			carouselImages: [
				berlinKop92CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Köpenicker Str. 92, 10179 Berlin',
		},

		[PropertyKey.ParnuEsp10]: {
			carouselImages: [
				parnuEsp10CarouselImage01,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Esplanaadi 10, Pärnu, 80014',
		},

		[PropertyKey.AmsterdamPap4]: {
			carouselImages: [
				amsterdamPap4CarouselImage01,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Papaverweg 4, 1032 KH Amsterdam',
		},

		[PropertyKey.LondonBea7]: {
			carouselImages: [
				londonBea7CarouselImage01,
				carouselKeyAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.PhysicalKey,
			propertyAddress: '7 Beaufort Gardens, London SW3 1PT',
		},

		[PropertyKey.AthensZal4]: {
			carouselImages: [
				athensZal4CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Merlin 7, Athens 106 71',
		},

		[PropertyKey.LondonPhi27]: {
			propertyAddress: '27-28 Philbeach Gdns London SW5 9EB, UK',
		},

		[PropertyKey.AthensVis2]: {
			carouselImages: [
				athensVis2CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Vissis 2, Athens 105 51',
		},
		[PropertyKey.CopenhagenTeg31]: {
			carouselImages: [
				copenhagenTeg31CarouselImage01,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Teglværksgade 31, 2100 Copenhagen Ø',
		},
		[PropertyKey.TurkuEer12]: {
			carouselImages: [
				carouselImg01TurkuEer12,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Eerikinkatu 12, Turku 20100',
		},
		[PropertyKey.LondonWs75]: {
			carouselImages: [
				carouselImg01LondonWs75,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Watling Street 75, EC4M 9BJ London',
		},
		[PropertyKey.TallinnLii34]: {
			carouselImages: [
				tallinnLii34CarouselImage01,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Liivalaia 34, 10118 Tallinn',
		},
		[PropertyKey.HelsinkiKas40]: {
			carouselImages: [
				carouselImg01HelsinkiKas40,
				carouselCodeAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Code,
			propertyAddress: 'Kasarmikatu 40, Helsinki 00130',
		},
		[PropertyKey.LondonCh65]: {
			carouselImages: [
				carouselImg01LondonCh65,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: '66-67 Chamber Street, London E1 8BL',
		},
		[PropertyKey.LondonHol31]: {
			carouselImages: [
				londonHol31CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: '31 Holland Road, London W14 8HJ',
		},
		[PropertyKey.LondonHol128]: {
			carouselImages: [
				londonHol128CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: '128 Holland Road, London W14 8BD',
		},
		[PropertyKey.MunichHoh9]: {
			carouselImages: [
				munichHoh9CarouselImage01,
				carouselKeycardAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.PhysicalKey,
			propertyAddress: 'Hohenzollernstraße 9, 80801 Munich',
		},
		[PropertyKey.MunichBaa1]: {
			carouselImages: [
				munichBaa1CarouselImage01,
				carouselKeycardAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.PhysicalKey,
			propertyAddress: 'Baaderstraße 1, 80469 Munich',
		},
		[PropertyKey.MunichFal22]: {
			carouselImages: [
				munichFal22CarouselImage01,
				carouselRemoteAccessImage,
				carouselImg03,
			],

			accessType: PropertyAccessType.Remote,
			propertyAddress: 'Fallmerayerstraße 22, 80796 Munich',
		},
	}

	const STEPS: StepsType = {
		CHECK_IN: {
			MAIN: {
				start: 'start',
				'main-guest-contacts': 'main-guest-contacts',
				'business-lead': 'business-lead',
				'main-guest-identification': 'main-guest-identification',
				guests: 'guests',
				times: 'times',
				products: 'products',
				'room-upgrade': 'room-upgrade',
				summary: 'summary',
			},
			COMPLETED: {
				success: 'success',
				'guest-area': 'guest-area',
			},
			SUB: {
				'main-guest-details': 'main-guest-details',
				'business-lead-form': 'business-lead-form',
				'additional-guest-details': 'additional-guest-details',
				'product-parking-indoor': 'product-parking-indoor',
				'product-breakfast': 'product-breakfast',
				'product-sauna-kit': 'product-sauna-kit',
				'product-mealkit-platform-pro': 'product-mealkit-platform-pro',
				'product-meal-kit-clean-kitchen-vegan-dan-dan-noodles-2': 'product-meal-kit-clean-kitchen-vegan-dan-dan-noodles-2',
				'product-stocked-fridge': 'product-stocked-fridge',
				'product-property-unit-category-upgrade-free': 'product-property-unit-category-upgrade-free',
				'product-property-unit-category-upgrade': 'product-property-unit-category-upgrade',
				'add-card': 'add-card',
				'guest-area-cleaning-mid-stay-intro': 'guest-area-cleaning-mid-stay-intro',
				'guest-area-cleaning-mid-stay-your-schedule': 'guest-area-cleaning-mid-stay-your-schedule',
				'guest-area-cleaning-mid-stay-select-time': 'guest-area-cleaning-mid-stay-select-time',
				'guest-area-cleaning-mid-stay-confirm': 'guest-area-cleaning-mid-stay-confirm',
				'guest-area-reservation-details': 'guest-area-reservation-details',
				'guest-area-apartment-details': 'guest-area-apartment-details',
				'guest-area-apartment-access-guidelines-madrid-chueca': 'guest-area-apartment-access-guidelines-madrid-chueca',
			},
		},
	}

	const ARRIVAL_TIME_STATUSES = {
		unconfirmedEarly: 'unconfirmed-early',
		confirmedEarly: 'confirmed-early',
		regular: 'regular',
	}

	const DEPARTURE_TIME_STATUSES = {
		unconfirmedLate: 'unconfirmed-late',
		confirmedLate: 'confirmed-late',
		regular: 'regular',
	}

	const SEX_OPTIONS = [
		{ value: 'female', label: i18next.t('Female') },
		{ value: 'male', label: i18next.t('Male') },
	]

	const DOCUMENT_TYPES: { value: CustomerDocumentApi['type'], label: string }[] = [
		{ value: 'passport', label: i18next.t('Passport') },
		{ value: 'identity-card', label: i18next.t('ID card') },
	]

	const TRAVEL_REASONS: TravelReasonApi[] = [
		{ value: 'business', label: i18next.t('Business') },
		{ value: 'holiday', label: i18next.t('Holiday') },
		{ value: 'remote work', label: i18next.t('Remote work') },
		{ value: 'family', label: i18next.t('Family visit') },
		{ value: 'staycation', label: i18next.t('Staycation') },
		{ value: 'other', label: i18next.t('Other') },
	]

	// compared current list to Mews countries list, only Kosovo was missing (state 01 Feb 2024)
	const countriesExtended = getCountries().concat([{ code: 'XK', name: 'Kosovo' }])

	const COUNTRIES: { code: string, name: string }[] = countriesExtended.sort((a, b) => {
		if (a.name < b.name) return -1
		if (a.name > b.name) return 1
		return 0
	})

	const EU_COUNTRIES = [
		'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'NL',
		'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'LI',
	]

	const SPAIN_REGULATION_ADDITIONAL_COUNTRIES = ['AD', 'IS', 'NO', 'MT', 'MC', 'CH', 'SM']

	//  Iceland, Liechtenstein, Norway, or Switzerland
	const DENMARK_REGULATION_ADDITIONAL_COUNTRIES = ['IS', 'LI', 'NO', 'CH']

	const COMMONWEALTH_COUNTRIES = [
		'AG', 'AU', 'BB', 'BD', 'BN', 'BS', 'BW', 'BZ', 'CA', 'CM', 'CY', 'DM', 'FJ', 'GB', 'GD', 'GH', 'GM', 'GY', 'IN',
		'JM', 'KE', 'KI', 'KN', 'LC', 'LK', 'LS', 'MT', 'MU', 'MW', 'MY', 'MZ', 'NA', 'NG', 'NR', 'NZ', 'PG', 'PK', 'RW',
		'SB', 'SC', 'SG', 'SL', 'SZ', 'TO', 'TT', 'TV', 'TZ', 'UG', 'VC', 'VU', 'WS', 'ZA', 'ZM',
	]

	const PAYMENT_METHODS = {
		visa: {
			icon: visaImg,
			cvvHelperText: i18next.t('3 digits on the back of your card'),
			cvvAbbreviation: 'CVV',
		},
		mastercard: {
			icon: mastercardImg,
			cvvHelperText: i18next.t('3 digits on the back of your card'),
			cvvAbbreviation: 'CVV',
		},
		amex: {
			icon: amexImg,
			cvvHelperText: i18next.t('4 digits on the front of your card'),
			cvvAbbreviation: 'CVV',
		},
		discover: {
			icon: discoverImg,
			cvvHelperText: i18next.t('3 digits on the back of your card'),
			cvvAbbreviation: 'CVV',
		},
		diners: {
			icon: dinersImg,
			cvvHelperText: i18next.t('3 digits on the back of your card'),
			cvvAbbreviation: 'CVV',
		},
		jcb: {
			icon: jcbImg,
			cvvHelperText: i18next.t('3 digits on the back of your card'),
			cvvAbbreviation: 'CVV',
		},
		unionpay: {
			icon: unionPayImg,
			cvvHelperText: i18next.t('3 digits on the back of your card'),
			cvvAbbreviation: 'CVV',
		},
	}

	const SECURE_FIELDS_ACCEPTABLE_PAYMENT_METHODS = ['ECA', 'VIS', 'AMX', 'DIN', 'DIS', 'JCB', 'CUP']

	const SECURE_FIELDS_INPUT_IDS = {
		cardNumber: 'card-number-secure-fields-input',
		cvv: 'cvv-secure-fields-input',
	}

	const PCI_PROXY_MERCHANT_IDS = {
		development: '1100007006',
	}

	const FRONT_CHAT_ID = '0849a7ec74fee80f9c03e9d0af053c39'
	const FRONT_CHAT_SCRIPT_URL = 'https://chat-assets.frontapp.com/v1/chat.bundle.js'

	const COMMON_STRINGS = {
		leaveStepWithoutSaving: i18next.t('Leave current step without saving the changes?'),
	}

	const GUEST_AREA_SURVEY_ID = IS_PRODUCTION
		? 'e9c4df302994c7fa'
		: '107a67b8de467481'

	const CHECK_IN_FLOW_SURVEY_ID = IS_PRODUCTION
		? '9c2257606a7bd04c'
		: 'df7b53ae9a14a6e2'

	const SURVICATE_LIBRARY_URL = 'https://survey.survicate.com/workspaces/342d0b585549219909291d12cdab82e1/web_surveys.js'

	const POLL_RESERVATIONS_INTERVAL = 60 * 1000 * 15 // 15 minutes

	/**
	 * https://www.riigiteataja.ee/akt/128122017031?leiaKehtiv#para24
	 * https://ec.europa.eu/eurostat/statistics-explained/index.php/Glossary:European_Economic_Area_(EEA)
	 *
	 * Why do we have names in these objects???
	 */
	const DOCUMENT_NOT_NEEDED_COUNTRIES = {
		EE: [
			{ code: 'BE', name: i18next.t('Belgium') },
			{ code: 'BG', name: i18next.t('Bulgaria') },
			{ code: 'CZ', name: i18next.t('Czechia') },
			{ code: 'DK', name: i18next.t('Denmark') },
			{ code: 'CY', name: i18next.t('Cyprus') },
			{ code: 'LV', name: i18next.t('Latvia') },
			{ code: 'LT', name: i18next.t('Lithuania') },
			{ code: 'LU', name: i18next.t('Luxembourg') },
			{ code: 'ES', name: i18next.t('Spain') },
			{ code: 'FR', name: i18next.t('France') },
			{ code: 'HR', name: i18next.t('Croatia') },
			{ code: 'IT', name: i18next.t('Italy') },
			{ code: 'PL', name: i18next.t('Poland') },
			{ code: 'PT', name: i18next.t('Portugal') },
			{ code: 'RO', name: i18next.t('Romania') },
			{ code: 'SI', name: i18next.t('Slovenia') },
			{ code: 'HU', name: i18next.t('Hungary') },
			{ code: 'MT', name: i18next.t('Malta') },
			{ code: 'NL', name: i18next.t('Netherlands') },
			{ code: 'AT', name: i18next.t('Austria') },
			{ code: 'IS', name: i18next.t('Iceland') },
			{ code: 'LI', name: i18next.t('Liechtenstein') },
			{ code: 'NO', name: i18next.t('Norway') },
			{ code: 'SK', name: i18next.t('Slovakia') },
			{ code: 'FI', name: i18next.t('Finland') },
			{ code: 'SE', name: i18next.t('Sweden') },
			{ code: 'CH', name: i18next.t('Switzerland') },
			{ code: 'DE', name: i18next.t('Germany') },
			{ code: 'EE', name: i18next.t('Estonia') },
			{ code: 'IE', name: i18next.t('Ireland') },
			{ code: 'GR', name: i18next.t('Greece') },
		],
		FI: [
			{ code: 'DK', name: i18next.t('Denmark') },
			{ code: 'IS', name: i18next.t('Iceland') },
			{ code: 'NO', name: i18next.t('Norway') },
			{ code: 'FI', name: i18next.t('Finland') },
			{ code: 'SE', name: i18next.t('Sweden') },
		],
		GB: [
			{ code: 'IE', name: i18next.t('Ireland') },
			...COMMONWEALTH_COUNTRIES.map((countryCode) => ({ code: countryCode })),
		],
	}

	const LANGUAGES = [
		{
			code: 'en-GB',
			label: 'English',
		},
		{
			code: 'es-ES',
			label: 'Español',
		},
		{
			code: 'et-EE',
			label: 'Eesti',
		},
		{
			code: 'fi-FI',
			label: 'Suomi',
		},
		{
			code: 'it-IT',
			label: 'Italiano',
		},
		{
			code: 'nl-NL',
			label: 'Nederlands',
		},
		{
			code: 'no-NO',
			label: 'Norsk',
		},
		{
			code: 'de-DE',
			label: 'Deutsch',
		},
		{
			code: 'el-GR',
			label: 'Ελληνικά',
		},
	]

	return {
		IS_PRODUCTION,
		IS_DEVELOPMENT,
		PropertyKey,
		PROPERTY_ADDITIONAL_DETAILS,
		STEPS,
		ARRIVAL_TIME_STATUSES,
		DEPARTURE_TIME_STATUSES,
		SEX_OPTIONS,
		DOCUMENT_TYPES,
		TRAVEL_REASONS,
		COUNTRIES,
		EU_COUNTRIES,
		SPAIN_REGULATION_ADDITIONAL_COUNTRIES,
		DENMARK_REGULATION_ADDITIONAL_COUNTRIES,
		COMMONWEALTH_COUNTRIES,
		ACTIVE_PRODUCT_KEYS,
		PRODUCT_CHARGE_METHODS,
		DISPLAYABLE_PRODUCTS_DETAILS,
		STOCKED_FRIDGE_DETAILS,
		PARKING_INDOOR_DETAILS,
		PAYMENT_METHODS,
		SECURE_FIELDS_ACCEPTABLE_PAYMENT_METHODS,
		SECURE_FIELDS_INPUT_IDS,
		PCI_PROXY_MERCHANT_IDS,
		FRONT_CHAT_ID,
		FRONT_CHAT_SCRIPT_URL,
		COMMON_STRINGS,
		CHECK_IN_FLOW_SURVEY_ID,
		GUEST_AREA_SURVEY_ID,
		SURVICATE_LIBRARY_URL,
		POLL_RESERVATIONS_INTERVAL,
		DOCUMENT_NOT_NEEDED_COUNTRIES,
		LANGUAGES,
	}
}

export default {
	...getConfig(),
}

export const analyticsBrand = 'Bob W'
export const countriesWithSubdivisions = new Set(['ES'])
