import { parseAdditionalGuests } from './guests.utils'
import GuestsActionTypes from './guests.types'

const guestsReducer = (state, action) => {
	switch (action.type) {
		// MAIN GUEST
		case GuestsActionTypes.UPDATE_MAIN_GUEST:
			return {
				...state,
				mainGuest: {
					...state.mainGuest,
					...action.payload,
				},
			}
		case GuestsActionTypes.UPDATE_MAIN_GUEST_CUSTOMER:
			return {
				...state,
				mainGuestCustomer: {
					...state.mainGuestCustomer,
					...action.payload,
				},
			}
		case GuestsActionTypes.UPDATE_MAIN_GUEST_DOCUMENT:
			return {
				...state,
				mainGuestDocument: {
					...state.mainGuestDocument,
					...action.payload,
				},
			}

		case GuestsActionTypes.UPDATE_MAIN_GUEST_ADDRESS:
			return {
				...state,
				mainGuestAddress: {
					...state.mainGuestAddress,
					...action.payload,
				},
			}

		// ADDITIONAL GUEST
		case GuestsActionTypes.INIT_CURRENT_ADDITIONAL_GUEST: {
			const {
				payload: { guestId, isEditing },
			} = action
			const newAdditionalGuest = {
				id: null,
				guest: {},
				customer: {},
				document: {},
				address: {},
			}
			const additionalGuest = guestId
				? state.additionalGuests.find((guest) => guest.id === guestId)
				: newAdditionalGuest

			return {
				...state,
				currentAdditionalGuest: {
					...additionalGuest,
					isEditing,
				},
			}
		}
		case GuestsActionTypes.CLEAR_CURRENT_ADDITIONAL_GUEST:
			return {
				...state,
				currentAdditionalGuest: null,
			}
		case GuestsActionTypes.UPDATE_CURRENT_ADDITIONAL_GUEST:
			return {
				...state,
				currentAdditionalGuest: {
					...state.currentAdditionalGuest,
					guest: {
						...state.currentAdditionalGuest.guest,
						...action.payload,
					},
				},
			}
		case GuestsActionTypes.UPDATE_CURRENT_ADDITIONAL_GUEST_CUSTOMER:
			return {
				...state,
				currentAdditionalGuest: {
					...state.currentAdditionalGuest,
					customer: {
						...state.currentAdditionalGuest.customer,
						...action.payload,
					},
				},
			}
		case GuestsActionTypes.UPDATE_CURRENT_ADDITIONAL_GUEST_DOCUMENT:
			return {
				...state,
				currentAdditionalGuest: {
					...state.currentAdditionalGuest,
					document: {
						...state.currentAdditionalGuest.document,
						...action.payload,
					},
				},
			}
		case GuestsActionTypes.UPDATE_CURRENT_ADDITIONAL_GUEST_ADDRESS:
			return {
				...state,
				currentAdditionalGuest: {
					...state.currentAdditionalGuest,
					address: {
						...state.currentAdditionalGuest.address,
						...action.payload,
					},
				},
			}

		// REMOVE ADDITIONAL GUEST
		case GuestsActionTypes.REMOVE_ADDITIONAL_GUEST_START:
			return {
				...state,
			}
		case GuestsActionTypes.REMOVE_ADDITIONAL_GUEST_SUCCESS: {
			const {
				reservationGuests,
				customer,
			} = action.payload
			const additionalGuests = reservationGuests.filter((x) => x.customer.id !== customer.id)

			return {
				...state,
				additionalGuests: parseAdditionalGuests(
					additionalGuests,
					customer,
					state.reservation.property.countryCode,
				),
			}
		}

		// PAYMENT
		case GuestsActionTypes.UPDATE_MAIN_GUEST_NEW_CREDIT_CARD: {
			const { mainGuestNewCreditCard } = state

			return {
				...state,
				mainGuestNewCreditCard: {
					...mainGuestNewCreditCard,
					...action.payload,
				},
			}
		}

		default:
			return state
	}
}

export default guestsReducer
