/* eslint import/no-cycle: 0 */
import logger from '../../../logger/logger'
import { client } from '@/api/feathers'
import { FLOW_SERVICE_MAP } from 'Types/app-state.types'
import { selectCurrentStepData } from '../../app/app.selectors'
import { setIsSubmitting, setSnackbarText, showAlertPopup } from '../../app/app.actions'
import GuestsActionTypes from './guests.types'
import { selectMainGuestAddress } from './guests.selectors'
import { selectReservationState } from 'Redux/reservation/reservation.selectors'
import { getAdultChildrenCount } from '@/utils'
import { reservationExpand } from 'Redux/reservation/reservation.actions'

/**
 * MAIN GUESTS
 */

const updateMainGuest = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_MAIN_GUEST,
		payload: data,
	})
}

const updateMainGuestCustomer = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_MAIN_GUEST_CUSTOMER,
		payload: data,
	})
}

const updateMainGuestDocument = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_MAIN_GUEST_DOCUMENT,
		payload: data,
	})
}

const updateMainGuestAddress = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_MAIN_GUEST_ADDRESS,
		payload: data,
	})
}

const updateMainGuestNewCreditCard = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_MAIN_GUEST_NEW_CREDIT_CARD,
		payload: data,
	})
}

/**
 * ADDITIONAL GUESTS
 */

const initCurrentAdditionalGuest = () => (dispatch, getState) => {
	const { guestId, isEditing } = selectCurrentStepData(getState())

	const editingValue = isEditing ?? false

	dispatch({
		type: GuestsActionTypes.INIT_CURRENT_ADDITIONAL_GUEST,
		payload: { guestId, isEditing: editingValue },
	})
}

const clearCurrentAdditionalGuest = () => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.CLEAR_CURRENT_ADDITIONAL_GUEST,
	})
}

const updateCurrentAdditionalGuest = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_CURRENT_ADDITIONAL_GUEST,
		payload: data,
	})
}

const updateCurrentAdditionalGuestCustomer = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_CURRENT_ADDITIONAL_GUEST_CUSTOMER,
		payload: data,
	})
}

const updateCurrentAdditionalGuestDocument = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_CURRENT_ADDITIONAL_GUEST_DOCUMENT,
		payload: data,
	})
}

const updateCurrentAdditionalGuestAddress = (data) => (dispatch) => {
	dispatch({
		type: GuestsActionTypes.UPDATE_CURRENT_ADDITIONAL_GUEST_ADDRESS,
		payload: data,
	})
}

const mainGuestAddressToCurrentAdditionalGuest = () => (dispatch, getState) => {
	const {
		addressLine1,
		postalCode,
		city,
		countryCode,
	} = selectMainGuestAddress(getState())

	dispatch(updateCurrentAdditionalGuestAddress({
		addressLine1,
		postalCode,
		city,
		countryCode,
	}))
}

/**
 * REMOVE ADDITIONAL GUEST
 */

const removeAdditionalGuestStart = () => (dispatch) => {
	dispatch(setIsSubmitting(true))
	dispatch(setSnackbarText('Saving data...'))
	dispatch({
		type: GuestsActionTypes.REMOVE_ADDITIONAL_GUEST_START,
	})
}

const removeAdditionalGuestSuccess = (reservation) => (dispatch) => {
	dispatch(setIsSubmitting(false))
	dispatch(setSnackbarText(null))
	dispatch({
		type: GuestsActionTypes.REMOVE_ADDITIONAL_GUEST_SUCCESS,
		payload: reservation,
	})
}

const removeAdditionalGuestFail = () => (dispatch) => {
	dispatch(setIsSubmitting(false))
	dispatch(setSnackbarText(null))
	dispatch(showAlertPopup('Could not remove guest. Please try again...'))
}

const removeAdditionalGuest = (reservationSlug, guestId) => (dispatch, getState) => {
	dispatch(removeAdditionalGuestStart())

	const reservationState = selectReservationState(getState())
	const mainCustomer = reservationState.mainGuestCustomer
	const additionalGuests = reservationState.additionalGuests ?? []
	const filteredAdditionalCustomers = additionalGuests
		.filter((guest) => guest.id !== guestId)
		.map((guest) => guest.customer)
	const customers = [mainCustomer, ...filteredAdditionalCustomers]
	const [adultCount, childrenCount] = getAdultChildrenCount(customers, 18)

	const data = {
		adultCount,
		childrenCount,
		reservationGuests: [
			{
				id: guestId,
				$action: 'remove',
			},
		],
	}

	client
		.service(FLOW_SERVICE_MAP.CHECK_IN)
		.patch(reservationSlug, data, {
			query: {
				$expand: reservationExpand,
			},
		})
		.then((reservation) => {
			dispatch(removeAdditionalGuestSuccess(reservation))
		})
		.catch((error) => {
			dispatch(removeAdditionalGuestFail(error.message))
			logger.error('Could not remove guest', error)
		})
}

export {
	updateMainGuest,
	updateMainGuestCustomer,
	updateMainGuestDocument,
	updateMainGuestAddress,
	updateMainGuestNewCreditCard,

	initCurrentAdditionalGuest,
	clearCurrentAdditionalGuest,
	updateCurrentAdditionalGuest,
	updateCurrentAdditionalGuestCustomer,
	updateCurrentAdditionalGuestDocument,
	updateCurrentAdditionalGuestAddress,
	mainGuestAddressToCurrentAdditionalGuest,

	removeAdditionalGuest,
}
