import format from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { differenceInYears, isAfter, isBefore, isSameSecond } from 'date-fns'

interface FormatBookingDatesRangeParams {
  readonly arrivalDateUtc: Date;
  readonly departureDateUtc: Date;
  readonly propertyTimeZone: string;
}

/**
 * https://github.com/date-fns/date-fns/issues/366#issuecomment-498029637
 */
export function sameOrBefore(
	date1 = new Date(),
	date2 = new Date(),
): boolean {
	if (isSameSecond(date1, date2)) {
		return true
	}

	return isBefore(date1, date2)
}

export function sameOrAfter(
	date1 = new Date(),
	date2 = new Date(),
): boolean {
	if (isSameSecond(date1, date2)) {
		return true
	}

	return isAfter(date1, date2)
}

export function isRangeBetween(
	[range1Start, range1End] = [new Date(), new Date()],
	[range2Start, range2End] = [new Date(), new Date()],
	includingDates = true,
): boolean {
	if (includingDates) {
		return sameOrBefore(range2Start, range1Start) && sameOrAfter(range2End, range1End)
	}

	return isBefore(range2Start, range1Start) && isAfter(range2End, range1End)
}

export function formatBookingDatesRange({
	arrivalDateUtc,
	departureDateUtc,
	propertyTimeZone,
} : FormatBookingDatesRangeParams) : string {
	const arrivalDate = utcToZonedTime(arrivalDateUtc, propertyTimeZone)
	const departureDate = utcToZonedTime(departureDateUtc, propertyTimeZone)

	const formattedArrivalDate = format(arrivalDate, 'd MMM', {
		timeZone: propertyTimeZone,
	})

	const formattedDepartureDate = format(departureDate, 'd MMM', {
		timeZone: propertyTimeZone,
	})

	return `${formattedArrivalDate} – ${formattedDepartureDate}`
}

export function getAge(dateOfBirth?: string): number | null {
	if (!dateOfBirth) {
		return null
	}

	const today = new Date()
	const birthDate = new Date(dateOfBirth)
	let age = differenceInYears(today, birthDate)

	if (isBefore(today, new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate()))) {
		age -= 1
	}

	return age
}
