import { createSelector } from 'reselect'
import { isEqual } from 'lodash'

import { getAdultChildrenCount, getChangedPropsOfObject } from '@/utils'

import { getLatestUpdated } from '../reservation/guests/guests.utils'

import { selectInitialReservationData } from '../reservation/reservation.selectors'
import {
	selectAllGuestsCustomers,
	selectCurrentAdditionalGuest,
	selectMainGuest,
	selectMainGuestAddress,
	selectMainGuestCustomer,
	selectMainGuestDocument,
	selectMainGuestNewCreditCard,
} from '../reservation/guests/guests.selectors'
import {
	selectCurrentCleaningDate,
	selectCurrentCleaningTime,
	selectSelectedCleaningDatesTimes,
} from '../reservation/cleaning-mid-stay/cleaning-mid-stay.selectors'
import { selectTravelReasons } from '../reservation/others/others.selectors'

import config from '../../globals/constants'

const { STEPS } = config

const { CHECK_IN } = STEPS
const CHECK_IN_STEPS = {
	...CHECK_IN.MAIN,
	...CHECK_IN.SUB,
	...CHECK_IN.COMPLETED,
}

const selectMainGuestChanges = createSelector(
	[
		selectMainGuest,
		selectMainGuestCustomer,
		selectMainGuestDocument,
		selectMainGuestAddress,
		selectInitialReservationData,
	],
	(
		guest,
		customer,
		guestDocument,
		guestAddress,
		initialReservation,
	) => {
		const {
			customer: initialCustomer,
			reservationGuests,
		} = initialReservation
		const initialGuest = reservationGuests?.find((guest) => guest.customer.id === customer.id)
		const initialDocument = getLatestUpdated(initialCustomer?.customerDocuments)

		const changedFields = {}
		const { visitedCountryCode, destinationCity, destinationCountryCode } = guest
		const { birthProvince, isMarketingConsentGiven } = customer ?? {}
		const { issueCountry, issueProvince, supportNumber } = guestDocument ?? {}
		const customerChangedFields = getChangedPropsOfObject(initialCustomer, customer)
		const signatureFieldChanged = customer.customerSignature
			&& (!initialCustomer?.customerSignature || initialCustomer?.customerSignatures?.length === 0)
		const documentChangedFields = getChangedPropsOfObject(initialDocument, guestDocument)
		const addressChangedFields = getChangedPropsOfObject(initialCustomer?.customerAddress, guestAddress)

		if (visitedCountryCode !== initialGuest?.visitedCountryCode) {
			changedFields.mainGuest = {
				...changedFields.mainGuest,
				visitedCountryCode,
			}
		}

		if (destinationCity !== initialGuest?.destinationCity) {
			changedFields.mainGuest = {
				...changedFields.mainGuest,
				destinationCity,
			}
		}

		if (destinationCountryCode !== initialGuest?.destinationCountryCode) {
			changedFields.mainGuest = {
				...changedFields.mainGuest,
				destinationCountryCode,
			}
		}

		if (customerChangedFields) {
			// API expects birthDate to be null if it's empty
			if (customerChangedFields.birthDate === '') {
				customerChangedFields.birthDate = null
			}

			changedFields.mainGuestCustomer = customerChangedFields

			const customers = reservationGuests.map((guest) => guest.customer) ?? []
			const customerIndex = customers.findIndex((currentCustomer) => currentCustomer.id === customer.id)

			customers[customerIndex] = {
				...customers[customerIndex],
				birthDate: customerChangedFields.birthDate,
			}

			const [adultCount, childrenCount] = getAdultChildrenCount(customers, 18)

			changedFields.adultCount = adultCount
			changedFields.childrenCount = childrenCount
		}

		// Sometimes customerChangedFields not returning changed consent in some reason, workaround
		if (isMarketingConsentGiven === true && initialCustomer?.isMarketingConsentGiven === undefined) {
			changedFields.mainGuestCustomer = {
				...changedFields.mainGuestCustomer,
				isMarketingConsentGiven,
			}
		}

		// Not changing getChangedPropsOfObject because we don't know what can be affected by it
		if (birthProvince !== initialCustomer?.birthProvince) {
			changedFields.mainGuestCustomer = {
				...changedFields.mainGuestCustomer,
				birthProvince,
			}
		}

		if (signatureFieldChanged) {
			changedFields.customerSignature = customer.customerSignature
		}

		if (documentChangedFields) {
			changedFields.mainGuestDocument = {
				type: guestDocument?.type,
				number: guestDocument?.number,
				...documentChangedFields,
			}
		}

		// Not changing getChangedPropsOfObject because we don't know what can be affected by it
		if (issueCountry !== initialCustomer?.issueCountry) {
			changedFields.mainGuestDocument = {
				type: guestDocument?.type,
				number: guestDocument?.number,
				...changedFields.mainGuestDocument,
				issueCountry,
			}
		}

		if (issueProvince !== initialCustomer?.issueProvince) {
			changedFields.mainGuestDocument = {
				type: guestDocument?.type,
				number: guestDocument?.number,
				...changedFields.mainGuestDocument,
				issueProvince,
			}
		}

		if (supportNumber !== initialCustomer?.supportNumber) {
			changedFields.mainGuestDocument = {
				type: guestDocument?.type,
				number: guestDocument?.number,
				...changedFields.mainGuestDocument,
				supportNumber,
			}
		}

		if (addressChangedFields) {
			changedFields.mainGuestAddress = addressChangedFields
		}

		return Object.keys(changedFields).length ? changedFields : null
	},
)

const selectMainGuestContactsChanges = createSelector(
	[
		selectMainGuestChanges,
		selectTravelReasons,
		selectInitialReservationData,
	],
	(
		guestChangedFields,
		travelReasons,
		initialReservation,
	) => {
		const { mainGuestCustomer } = guestChangedFields || {}
		const changedFields = {}

		if (guestChangedFields) {
			changedFields.mainGuestCustomer = mainGuestCustomer
		}

		if (!isEqual(travelReasons, initialReservation.travelReasons)) {
			changedFields.travelReasons = travelReasons
		}

		return Object.keys(changedFields).length ? changedFields : null
	},
)

const selectGuestsChanges = createSelector(
	[
		selectAllGuestsCustomers,
		selectInitialReservationData,
	],
	(
		customers,
		initialReservation,
	) => {
		const [adultCount, childrenCount] = getAdultChildrenCount(customers, 18)

		if (
			adultCount !== initialReservation.adultCount
			|| childrenCount !== initialReservation.childrenCount
		) {
			return { adultCount, childrenCount }
		}

		return null
	},
)

const selectAdditionalGuestDetailsChanges = createSelector(
	[
		selectCurrentAdditionalGuest,
		selectInitialReservationData,
	],
	(
		{
			guest,
			customer,
			document,
			address,
		},
		initialReservation,
	) => {
		const { id: guestCustomerId } = customer

		const initialGuest = guestCustomerId && initialReservation
			.reservationGuests
			.find((guest) => guest.customer.id === guestCustomerId)
		const initialCustomer = initialGuest?.customer
		const initialDocument = getLatestUpdated(initialCustomer?.customerDocuments)

		const changedFields = {}
		const { birthProvince } = customer
		const { issueCountry, issueProvince, supportNumber } = document ?? {}
		const signatureFieldChanged = customer.customerSignature
			&& (!initialCustomer?.customerSignature || initialCustomer?.customerSignatures?.length === 0)
		const guestCustomerChangedFields = getChangedPropsOfObject(initialCustomer, customer)
		const documentChangedFields = getChangedPropsOfObject(initialDocument, document)
		const addressChangedFields = getChangedPropsOfObject(initialCustomer?.customerAddress, address)

		const currentAdditionalGuestChangedFields = getChangedPropsOfObject(initialGuest, guest)

		if (currentAdditionalGuestChangedFields) {
			changedFields.currentAdditionalGuest = currentAdditionalGuestChangedFields
		}

		if (guestCustomerChangedFields) {
			// API expects birthDate to be null if it's empty
			if (guestCustomerChangedFields.birthDate === '') {
				guestCustomerChangedFields.birthDate = null
			}

			changedFields.currentAdditionalGuestCustomer = guestCustomerChangedFields

			const customers = initialReservation.reservationGuests.map((guest) => guest.customer) ?? []

			if (initialGuest) {
				const customerIndex = customers.findIndex((customer) => customer.id === guestCustomerId)

				customers[customerIndex] = {
					...customers[customerIndex],
					birthDate: guestCustomerChangedFields.birthDate,
				}
			} else {
				customers.push({ birthDate: guestCustomerChangedFields.birthDate })
			}

			const [adultCount, childrenCount] = getAdultChildrenCount(customers, 18)

			changedFields.adultCount = adultCount
			changedFields.childrenCount = childrenCount
		}

		if (signatureFieldChanged && customer.customerSignature?.id === undefined) {
			changedFields.customerSignature = customer.customerSignature
		}

		// Not changing getChangedPropsOfObject because we don't know what can be affected by it
		if (birthProvince !== initialCustomer?.birthProvince) {
			changedFields.currentAdditionalGuestCustomer = {
				...changedFields.currentAdditionalGuestCustomer,
				birthProvince,
			}
		}

		if (documentChangedFields) {
			changedFields.currentAdditionalGuestDocument = {
				number: initialDocument?.number,
				type: initialDocument?.type,
				...documentChangedFields,
			}
		}

		// Not changing getChangedPropsOfObject because we don't know what can be affected by it
		if (issueCountry !== initialCustomer?.issueCountry) {
			changedFields.currentAdditionalGuestDocument = {
				type: document?.type,
				number: document?.number,
				...changedFields.currentAdditionalGuestDocument,
				issueCountry,
			}
		}

		if (issueProvince !== initialCustomer?.issueProvince) {
			changedFields.currentAdditionalGuestDocument = {
				type: document?.type,
				number: document?.number,
				...changedFields.currentAdditionalGuestDocument,
				issueProvince,
			}

			if (supportNumber !== initialCustomer?.supportNumber) {
				changedFields.currentAdditionalGuestDocument = {
					type: document?.type,
					number: document?.number,
					...changedFields.currentAdditionalGuestDocument,
					supportNumber,
				}
			}
		}

		if (addressChangedFields) {
			changedFields.currentAdditionalGuestAddress = addressChangedFields
		}

		return Object.keys(changedFields) ? changedFields : null
	},
)

const selectAddCardChanges = createSelector(
	[selectMainGuestNewCreditCard],
	(creditCard) => {
		const {
			name,
			cardNumber,
			expirationDate,
			cvv,
		} = creditCard || {}

		const changedFields = {}

		if (name) changedFields.name = name
		if (cardNumber?.length) changedFields.cardNumber = true
		if (expirationDate) changedFields.expirationDate = expirationDate
		if (cvv?.length) changedFields.cvv = true

		return Object.keys(changedFields) ? changedFields : null
	},
)

const selectCleaningMidStaySelectTimeChanges = createSelector(
	[
		selectCurrentCleaningDate,
		selectCurrentCleaningTime,
	],
	(
		cleaningDate,
		cleaningTime,
	) => {
		const changedFields = {}

		if (cleaningDate) changedFields.cleaningDate = cleaningDate
		if (cleaningTime) changedFields.cleaningTime = cleaningTime

		return Object.keys(changedFields) ? changedFields : null
	},
)

const selectCleaningMidStayConfirmChanges = createSelector(
	[selectSelectedCleaningDatesTimes],
	(selectedCleaningDatesTimes) => {
		const changedFields = {}

		if (selectedCleaningDatesTimes?.length) changedFields.selectedCleaningDatesTimes = selectedCleaningDatesTimes

		return Object.keys(changedFields) ? changedFields : null
	},
)

const getStepAPIChangesSelector = (step) => {
	switch (step) {
		case CHECK_IN_STEPS['main-guest-contacts']:
			return selectMainGuestContactsChanges

		case CHECK_IN_STEPS['main-guest-identification']:
			return selectMainGuestChanges

		case CHECK_IN_STEPS['guests']:
			return selectGuestsChanges

		case CHECK_IN_STEPS['main-guest-details']:
			return selectMainGuestChanges

		case CHECK_IN_STEPS['additional-guest-details']:
			return selectAdditionalGuestDetailsChanges

		case CHECK_IN_STEPS['add-card']:
			return selectAddCardChanges

		case CHECK_IN_STEPS['guest-area-cleaning-mid-stay-select-time']:
			return selectCleaningMidStaySelectTimeChanges

		case CHECK_IN_STEPS['guest-area-cleaning-mid-stay-confirm']:
			return selectCleaningMidStayConfirmChanges

		default:
			return () => null
	}
}

export default getStepAPIChangesSelector
