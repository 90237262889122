import { createSelector } from 'reselect'

import {
	selectAdditionalGuests,
	selectAddressRequired,
	selectCurrentAdditionalGuest, selectFireSafetyRequired,
	selectMainGuest,
	selectMainGuestAddress,
	selectMainGuestBirthDateRequired,
	selectMainGuestCurrentCreditCard,
	selectMainGuestCustomer,
	selectMainGuestDocument,
	selectMainGuestDocumentDetailsRequired,
	selectMainGuestGenderRequired,
	selectMainGuestNewCreditCard,
	selectMainGuestResidencyProvinceRequired,
	selectMainGuestSignatureRequired,
	selectMainGuestVisitedCountryRequired,
	selectNextDestinationRequired, selectPostalCodeRequired,
} from '../reservation/guests/guests.selectors'
import { selectArrivalTimeData, selectDepartureTimeData } from '../reservation/times/times.selectors'
import {
	selectHasImmediatelyPurchasablePaidProducts,
	selectHasOrderedParking,
	selectOrderedProducts,
	selectProductsAdditionalData,
	selectProductsConsumptionDate,
} from '../reservation/products/products.selectors'
import {
	selectCurrentCleaningDate,
	selectCurrentCleaningTime,
} from '../reservation/cleaning-mid-stay/cleaning-mid-stay.selectors'

import { validateAdditionalGuest, validateEmail, validateExpirationDate } from '../reservation/guests/guests.utils'
import {
	selectReservation,
	selectReservationPropertyCountryCode,
} from '../reservation/reservation.selectors'

import config from 'Globals/constants'
import { isDocumentSupportNumberRequired } from '@/utils'

const { ACTIVE_PRODUCT_KEYS, STEPS } = config

const { CHECK_IN } = STEPS
const CHECK_IN_STEPS = {
	...CHECK_IN.MAIN,
	...CHECK_IN.SUB,
	...CHECK_IN.COMPLETED,
}

const selectMainGuestContactsErrors = createSelector(
	[
		selectMainGuestCustomer,
		selectOrderedProducts,
		selectProductsAdditionalData,
		selectHasOrderedParking,
	],
	(
		customer,
		orderedProducts,
		productsAdditionalData,
		hasOrderedParking,
	) => {
		const errors = []

		const {
			email,
			phone,
			phoneCountryCallingCode: callingCode,
		} = customer

		if (!validateEmail(email)) errors.push('email')
		if (!phone) errors.push('phone') // proper phone validation in BE
		if (!callingCode || callingCode === -1) errors.push('phone')
		const hasCarPlate = !!productsAdditionalData.find(
			(prodData) => prodData.productKey === ACTIVE_PRODUCT_KEYS.parking,
		)?.data?.carPlateNumber
		if (hasOrderedParking && !hasCarPlate) {
			errors.push('carPlate')
		}
		// note: travel reasons field needs no validation

		return errors
	},
)

const selectMainGuestIdentificationErrors = createSelector(
	[
		selectReservationPropertyCountryCode,
		selectMainGuest,
		selectMainGuestCustomer,
		selectMainGuestDocument,
		selectMainGuestAddress,
		selectMainGuestDocumentDetailsRequired,
		selectMainGuestVisitedCountryRequired,
		selectMainGuestGenderRequired,
		selectMainGuestSignatureRequired,
		selectNextDestinationRequired,
		selectAddressRequired,
		selectPostalCodeRequired,
		selectFireSafetyRequired,
		selectMainGuestResidencyProvinceRequired,
		selectMainGuestBirthDateRequired,
	],
	(
		propertyCountryCode,
		guest,
		customer,
		guestDocument,
		guestAddress,
		documentDetailsRequired,
		visitedCountryRequired,
		genderRequired,
		signatureRequired,
		nextDestinationRequired,
		addressRequired,
		isPostalCodeRequired,
		isFireSafetyRequired,
		isMainGuestResidencyProvinceRequired,
		birthDateRequired,
	) => {
		const errors = []

		const { visitedCountryCode, destinationCity, destinationCountryCode } = guest
		const {
			firstName,
			lastName,
			birthDate,
			birthCountryCode,
			birthProvince,
			nationalityCode,
			customerSignature,
			gender,
			isFireSafetyConsentGiven,
		} = customer
		const {
			type: documentType,
			number: documentNumber,
			supportNumber: documentSupportNumber,
			issueCountry: documentIssueCountry,
			issueProvince: documentIssueProvince,
		} = guestDocument || {}
		const {
			addressLine1,
			postalCode,
			city,
			countryCode,
			countrySubdivisionCode,
		} = guestAddress || {}

		if (!firstName) errors.push('firstName')
		if (!lastName) errors.push('lastName')
		if (birthDateRequired && !birthDate) errors.push('birthDate')
		if (propertyCountryCode === 'IT') {
			if (!birthCountryCode) errors.push('birthCountryCode')
			if (!birthProvince && birthCountryCode === 'IT') errors.push('birthProvince')
		}
		if (!nationalityCode) errors.push('nationalityCode')

		if (addressRequired && !addressLine1) errors.push('addressLine1')
		if (isPostalCodeRequired && !postalCode) errors.push('postalCode')
		if (!city) errors.push('city')
		if (!countryCode) errors.push('countryCode')
		if (isMainGuestResidencyProvinceRequired && typeof countrySubdivisionCode !== 'string') errors.push('countrySubdivisionCode')

		if (documentDetailsRequired) {
			if (!documentType) errors.push('documentType')
			if (!documentNumber) errors.push('documentNumber')

			const isSupportDocumentRequired = isDocumentSupportNumberRequired(
				propertyCountryCode,
				nationalityCode,
				documentType,
			)

			if (isSupportDocumentRequired) {
				if (!documentSupportNumber) errors.push('documentSupportNumber')
			}

			if (propertyCountryCode === 'IT') {
				if (!documentIssueCountry) errors.push('documentIssueCountry')
				if (!documentIssueProvince && documentIssueCountry === 'IT') errors.push('documentIssueProvince')
			}
		}

		if (genderRequired) {
			if (!gender) errors.push('gender')
		}

		if (signatureRequired && !customerSignature) {
			errors.push('signature')
		}

		if (visitedCountryRequired) {
			if (!visitedCountryCode) errors.push('visitedCountryCode')
		}

		if (nextDestinationRequired) {
			if (!destinationCountryCode) errors.push('destinationCountryCode')
			if (!destinationCity) errors.push('destinationCity')
		}

		if (isFireSafetyRequired && !isFireSafetyConsentGiven) {
			errors.push('fireSafetyConsent')
		}

		return errors
	},
)

const selectMainGuestDetailsErrors = createSelector(
	[
		selectReservationPropertyCountryCode,
		selectMainGuest,
		selectMainGuestCustomer,
		selectMainGuestDocument,
		selectMainGuestAddress,
		selectMainGuestDocumentDetailsRequired,
		selectMainGuestVisitedCountryRequired,
		selectMainGuestGenderRequired,
		selectNextDestinationRequired,
		selectAddressRequired,
		selectPostalCodeRequired,
		selectMainGuestResidencyProvinceRequired,
		selectMainGuestBirthDateRequired,
	],
	(
		propertyCountryCode,
		guest,
		customer,
		guestDocument,
		guestAddress,
		documentDetailsRequired,
		visitedCountryRequired,
		genderRequired,
		nextDestinationRequired,
		addressRequired,
		isPostalCodeRequired,
		isMainGuestResidencyProvinceRequired,
		birthDateRequired,
	) => {
		const errors = []

		const { visitedCountryCode, destinationCountryCode, destinationCity } = guest
		const {
			firstName,
			lastName,
			birthDate,
			birthCountryCode,
			birthProvince,
			nationalityCode,
			email,
			phone,
			gender,
			phoneCountryCallingCode: callingCode,
		} = customer
		const {
			type: documentType,
			number: documentNumber,
			supportNumber: documentSupportNumber,
			issueCountry: documentIssueCountry,
			issueProvince: documentIssueProvince,
		} = guestDocument || {}
		const {
			addressLine1,
			postalCode,
			city,
			countryCode,
			countrySubdivisionCode,
		} = guestAddress || {}

		if (!firstName) errors.push('firstName')
		if (!lastName) errors.push('lastName')
		if (birthDateRequired && !birthDate) errors.push('birthDate')
		if (propertyCountryCode === 'IT') {
			if (!birthCountryCode) errors.push('birthCountryCode')
			if (!birthProvince && birthCountryCode === 'IT') errors.push('birthProvince')
		}
		if (!nationalityCode) errors.push('nationalityCode')

		if (addressRequired && !addressLine1) errors.push('addressLine1')
		if (isPostalCodeRequired && !postalCode) errors.push('postalCode')
		if (!city) errors.push('city')
		if (!countryCode) errors.push('countryCode')
		if (isMainGuestResidencyProvinceRequired && typeof countrySubdivisionCode !== 'string') errors.push('countrySubdivisionCode')

		if (!validateEmail(email)) errors.push('email')
		if (!phone) errors.push('phone') // proper phone validation in BE
		if (!callingCode || callingCode === -1) errors.push('phone')

		if (documentDetailsRequired) {
			if (!documentType) errors.push('documentType')
			if (!documentNumber) errors.push('documentNumber')

			const isSupportDocumentRequired = isDocumentSupportNumberRequired(
				propertyCountryCode,
				nationalityCode,
				documentType,
			)

			if (isSupportDocumentRequired) {
				if (!documentSupportNumber) errors.push('documentSupportNumber')
			}

			if (propertyCountryCode === 'IT') {
				if (!documentIssueCountry) errors.push('documentIssueCountry')
				if (!documentIssueProvince && documentIssueCountry === 'IT') errors.push('documentIssueProvince')
			}
		}

		if (genderRequired) {
			if (!gender) errors.push('gender')
		}

		if (visitedCountryRequired) {
			if (!visitedCountryCode) errors.push('visitedCountryCode')
		}

		if (nextDestinationRequired) {
			if (!destinationCountryCode) errors.push('destinationCountryCode')
			if (!destinationCity) errors.push('destinationCity')
		}

		return errors
	},
)

const selectAdditionalGuestDetailsErrors = createSelector(
	[
		selectReservation,
		selectCurrentAdditionalGuest,
		selectMainGuestCustomer,
		selectAdditionalGuests,
	],
	(
		{ property },
		{
			guest,
			customer,
			document,
			address,
		},
		{ email: mainGuestEmail },
		additionalGuests,
	) => validateAdditionalGuest(
		{
			guest,
			customer,
			document,
			address,
			propertyCountryCode: property.countryCode,
			mainGuestEmail: mainGuestEmail?.trim().toLowerCase(),
			additionalGuests,
		},
	),
)

const selectTimesErrors = createSelector(
	[
		selectArrivalTimeData,
		selectDepartureTimeData,
	],
	(
		arrivalTimeData,
		departureTimeData,
	) => {
		const { selectedArrivalTimeUTC } = arrivalTimeData
		const { selectedDepartureTimeUTC } = departureTimeData

		const errors = []

		if (!selectedArrivalTimeUTC) errors.push('arrivalTime')
		if (!selectedDepartureTimeUTC) errors.push('departureTime')

		return errors
	},
)

const selectProductParkingErrors = createSelector(
	[selectProductsAdditionalData],
	(productsAdditionalData) => {
		const hasCarPlate = !!productsAdditionalData.find(
			(prodData) => prodData.productKey === ACTIVE_PRODUCT_KEYS.parking,
		)?.data?.carPlateNumber
		return !hasCarPlate ? ['carPlate'] : []
	},
)

const selectMealKitErrors = createSelector(
	[selectProductsConsumptionDate],
	(productsConsumptionDates) => {
		const hasDeliveryDate = !!productsConsumptionDates
			.find((date) => date.productKey === ACTIVE_PRODUCT_KEYS.mealkit)
			?.consumptionDate
		return !hasDeliveryDate ? ['deliveryDate'] : []
	},
)

const selectSummaryErrors = createSelector(
	[
		selectHasImmediatelyPurchasablePaidProducts,
		selectMainGuestCurrentCreditCard,
	],
	(
		hasImmediatelyPurchasablePaidProducts,
		creditCard,
	) => {
		return hasImmediatelyPurchasablePaidProducts && !creditCard ? ['noCreditCard'] : []
	},
)

const selectAddCardErrors = createSelector(
	[selectMainGuestNewCreditCard],
	(creditCard) => {
		const {
			name,
			cardNumber,
			expirationDate,
			cvv,
		} = creditCard || {}
		const errors = []

		if (!name) errors.push('name')
		if (!cardNumber || !cardNumber.valid) errors.push('cardNumber')
		if (!validateExpirationDate(expirationDate)) errors.push('expirationDate')
		if (!cvv || !cvv.length || !cvv.valid) errors.push('cvv')

		return errors
	},
)

const selectGuestAreaCleaningMidStayScheduleErrors = createSelector(
	[
		selectCurrentCleaningDate,
		selectCurrentCleaningTime,
	],
	(
		cleaningDate,
		cleaningTime,
	) => {
		const errors = []

		if (!cleaningDate) errors.push('cleaningDate')
		if (!cleaningTime) errors.push('cleaningTime')

		return errors
	},
)

const getStepFormErrorsSelector = (step) => {
	switch (step) {
		case CHECK_IN_STEPS['main-guest-contacts']:
			return selectMainGuestContactsErrors

		case CHECK_IN_STEPS['main-guest-identification']:
			return selectMainGuestIdentificationErrors

		case CHECK_IN_STEPS['main-guest-details']:
			return selectMainGuestDetailsErrors

		case CHECK_IN_STEPS['additional-guest-details']:
			return selectAdditionalGuestDetailsErrors

		case CHECK_IN_STEPS['times']:
			return selectTimesErrors

		case CHECK_IN_STEPS['product-parking-indoor']:
			return selectProductParkingErrors

		case CHECK_IN_STEPS['product-mealkit-platform-pro']:
			return selectMealKitErrors

		case CHECK_IN_STEPS['summary']:
			return selectSummaryErrors

		case CHECK_IN_STEPS['add-card']:
			return selectAddCardErrors

		case CHECK_IN_STEPS['guest-area-cleaning-mid-stay-select-time']:
			return selectGuestAreaCleaningMidStayScheduleErrors

		default:
			return () => null
	}
}

export default getStepFormErrorsSelector
