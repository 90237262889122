/* eslint import/no-cycle: 0 */
import { updateSelectedArrivalTime, updateSelectedDepartureTime } from '../reservation/times/times.actions'
import {
	updateSelectedProductAmount,
	updateSelectedProductConsumptionDate,
	updateSelectedProductKeys,
	updateSelectedProductsAdditionalData,
} from '../reservation/products/products.actions'
import { updateTravelReasons } from '../reservation/others/others.actions'
import { updateReservation } from '../reservation/reservation.actions'
import {
	updateCurrentAdditionalGuest,
	updateCurrentAdditionalGuestAddress,
	updateCurrentAdditionalGuestCustomer,
	updateCurrentAdditionalGuestDocument,
	updateMainGuest,
	updateMainGuestAddress,
	updateMainGuestCustomer,
	updateMainGuestDocument,
	updateMainGuestNewCreditCard,
} from '../reservation/guests/guests.actions'
import {
	updateCurrentCleaningDate,
	updateCurrentCleaningTime,
} from '../reservation/cleaning-mid-stay/cleaning-mid-stay.actions'

import config from '../../globals/constants'
import {
	updateBusinessLeadContactEmail,
	updateBusinessLeadContactPerson,
	updateBusinessLeadCompanyName,
	updateBusinessLeadCompanySize, updateBusinessLeadContactJobTitle,
} from 'Redux/business-lead/business-lead.actions'

const { STEPS } = config

const { CHECK_IN } = STEPS
const CHECK_IN_STEPS = {
	...CHECK_IN.MAIN,
	...CHECK_IN.SUB,
	...CHECK_IN.COMPLETED,
}

const MainGuestFieldUpdaters = {
	firstName: (firstName) => (dispatch) => dispatch(updateMainGuestCustomer({ firstName })),
	lastName: (lastName) => (dispatch) => dispatch(updateMainGuestCustomer({ lastName })),
	birthDate: (birthDate) => (dispatch) => dispatch(updateMainGuestCustomer({ birthDate })),
	birthCountryCode: (birthCountryCode) => (dispatch) => dispatch(updateMainGuestCustomer({ birthCountryCode })),
	birthProvince: (birthProvince) => (dispatch) => dispatch(updateMainGuestCustomer({ birthProvince })),
	nationalityCode: (nationalityCode) => (dispatch) => dispatch(updateMainGuestCustomer({ nationalityCode })),

	addressLine1: (addressLine1) => (dispatch) => dispatch(updateMainGuestAddress({ addressLine1 })),
	postalCode: (postalCode) => (dispatch) => dispatch(updateMainGuestAddress({ postalCode })),
	city: (city) => (dispatch) => dispatch(updateMainGuestAddress({ city })),
	countryCode: (countryCode) => (dispatch) => dispatch(updateMainGuestAddress({ countryCode })),

	countrySubdivisionCode: (countrySubdivisionCode) => (dispatch) => dispatch(
		updateMainGuestAddress({ countrySubdivisionCode }),
	),

	email: (email) => (dispatch) => dispatch(updateMainGuestCustomer({ email })),
	phone: (phoneData) => (dispatch) => dispatch(updateMainGuestCustomer(phoneData)),
	gender: (gender) => (dispatch) => dispatch(updateMainGuestCustomer({ gender })),
	carPlate: ({ productKey, carPlate }) => (dispatch) => dispatch(
		updateSelectedProductsAdditionalData(productKey, { carPlateNumber: carPlate }),
	),

	documentType: (type) => (dispatch) => dispatch(updateMainGuestDocument({ type })),
	documentNumber: (number) => (dispatch) => dispatch(updateMainGuestDocument({ number })),
	documentIssueCountry: (country) => (dispatch) => dispatch(updateMainGuestDocument({ issueCountry: country })),
	documentIssueProvince: (province) => (dispatch) => dispatch(updateMainGuestDocument({ issueProvince: province })),
	documentSupportNumber: (number) => (dispatch) => dispatch(
		updateMainGuestDocument({ supportNumber: number }),
	),
	visitedCountryCode: (visitedCountryCode) => (dispatch) => dispatch(updateMainGuest({ visitedCountryCode })),
	destinationCountryCode: (destinationCountryCode) => (dispatch) => dispatch(
		updateMainGuest({ destinationCountryCode }),
	),
	destinationCity: (destinationCity) => (dispatch) => dispatch(
		updateMainGuest({ destinationCity }),
	),

	signature: (signature) => (dispatch) => (dispatch(updateMainGuestCustomer({
		customerSignature: signature,
	}))),

	travelReasons: (travelReasons) => (dispatch) => dispatch(updateTravelReasons(travelReasons)),
	isMarketingConsentGiven: (isMarketingConsentGiven) => (dispatch) => dispatch(
		updateMainGuestCustomer({ isMarketingConsentGiven }),
	),
	isFireSafetyConsentGiven: (isFireSafetyConsentGiven) => (dispatch) => dispatch(
		updateMainGuestCustomer({ isFireSafetyConsentGiven }),
	),
}

const BusinessLeadUpdaters = {
	contactPerson: (contactPerson) => (dispatch) => dispatch(updateBusinessLeadContactPerson(contactPerson)),
	contactEmail: (contactEmail) => (dispatch) => dispatch(updateBusinessLeadContactEmail(contactEmail)),
	contactJobTitle: (contactJobTitle) => (dispatch) => dispatch(updateBusinessLeadContactJobTitle(contactJobTitle)),
	companyName: (companyName) => (dispatch) => dispatch(updateBusinessLeadCompanyName(companyName)),
	companySizeRange: (companySizeRange) => (dispatch) => dispatch(updateBusinessLeadCompanySize(companySizeRange)),
}

const StepFieldUpdaters = {
	[CHECK_IN_STEPS['main-guest-contacts']]: MainGuestFieldUpdaters,
	[CHECK_IN_STEPS['main-guest-identification']]: MainGuestFieldUpdaters,
	[CHECK_IN_STEPS['guests']]: {
		guestCount: (guestCount) => (dispatch) => dispatch(
			updateReservation({
				adultCount: guestCount,
				childrenCount: 0,
			}),
		),
	},
	[CHECK_IN_STEPS['business-lead-form']]: BusinessLeadUpdaters,
	[CHECK_IN_STEPS['main-guest-details']]: MainGuestFieldUpdaters,
	[CHECK_IN_STEPS['additional-guest-details']]: {
		firstName: (firstName) => (dispatch) => dispatch(updateCurrentAdditionalGuestCustomer({ firstName })),
		lastName: (lastName) => (dispatch) => dispatch(updateCurrentAdditionalGuestCustomer({ lastName })),
		birthDate: (birthDate) => (dispatch) => dispatch(updateCurrentAdditionalGuestCustomer({ birthDate })),
		birthCountryCode: (birthCountryCode) => (dispatch) => dispatch(
			updateCurrentAdditionalGuestCustomer({ birthCountryCode }),
		),
		birthProvince: (birthProvince) => (dispatch) => dispatch(
			updateCurrentAdditionalGuestCustomer({ birthProvince }),
		),
		nationalityCode: (nationalityCode) => (dispatch) => dispatch(
			updateCurrentAdditionalGuestCustomer({ nationalityCode }),
		),

		gender: (gender) => (dispatch) => dispatch(updateCurrentAdditionalGuestCustomer({ gender })),

		addressLine1: (addressLine1) => (dispatch) => dispatch(updateCurrentAdditionalGuestAddress({ addressLine1 })),
		postalCode: (postalCode) => (dispatch) => dispatch(updateCurrentAdditionalGuestAddress({ postalCode })),
		city: (city) => (dispatch) => dispatch(updateCurrentAdditionalGuestAddress({ city })),
		countryCode: (countryCode) => (dispatch) => dispatch(updateCurrentAdditionalGuestAddress({ countryCode })),

		email: (email) => (dispatch) => dispatch(updateCurrentAdditionalGuestCustomer({ email })),
		phone: (phoneData) => (dispatch) => dispatch(updateCurrentAdditionalGuestCustomer(phoneData)),

		documentType: (type) => (dispatch) => dispatch(updateCurrentAdditionalGuestDocument({ type })),
		documentNumber: (number) => (dispatch) => dispatch(updateCurrentAdditionalGuestDocument({ number })),
		documentIssueCountry: (country) => (dispatch) => dispatch(updateCurrentAdditionalGuestDocument({
			issueCountry: country,
		})),
		documentIssueProvince: (province) => (dispatch) => dispatch(updateCurrentAdditionalGuestDocument({
			issueProvince: province,
		})),
		documentSupportNumber: (number) => (dispatch) => dispatch(
			updateCurrentAdditionalGuestDocument({ supportNumber: number }),
		),
		visitedCountryCode: (visitedCountryCode) => (dispatch) => dispatch(
			updateCurrentAdditionalGuest({ visitedCountryCode }),
		),
		destinationCountryCode: (destinationCountryCode) => (dispatch) => dispatch(
			updateCurrentAdditionalGuest({ destinationCountryCode }),
		),
		destinationCity: (destinationCity) => (dispatch) => dispatch(
			updateCurrentAdditionalGuest({ destinationCity }),
		),
		signature: (signature) => (dispatch) => (dispatch(updateCurrentAdditionalGuestCustomer({
			customerSignature: signature,
		}))),
	},
	[CHECK_IN_STEPS['times']]: {
		arrivalTime: (arrivalTime) => (dispatch) => dispatch(updateSelectedArrivalTime(arrivalTime)),
		departureTime: (departureTime) => (dispatch) => dispatch(updateSelectedDepartureTime(departureTime)),
		products: (productKeys) => (dispatch) => dispatch(updateSelectedProductKeys(productKeys)),
	},
	[CHECK_IN_STEPS['products']]: {
		products: (productKeys) => (dispatch) => dispatch(updateSelectedProductKeys(productKeys)),
	},
	[CHECK_IN_STEPS['product-parking-indoor']]: {
		products: (productKey) => (dispatch) => dispatch(updateSelectedProductKeys(productKey)),
		carPlate: ({ productKey, carPlate }) => (dispatch) => dispatch(
			updateSelectedProductsAdditionalData(productKey, { carPlateNumber: carPlate }),
		),
	},
	[CHECK_IN_STEPS['product-breakfast']]: {
		products: (productKey) => (dispatch) => dispatch(updateSelectedProductKeys(productKey)),
	},
	[CHECK_IN_STEPS['product-sauna-kit']]: {
		products: (productKey) => (dispatch) => dispatch(updateSelectedProductKeys(productKey)),
	},
	[CHECK_IN_STEPS['product-mealkit-platform-pro']]: {
		products: (productKey) => (dispatch) => dispatch(updateSelectedProductKeys(productKey)),
		deliveryDate: ({ productKey, deliveryDate }) => (dispatch) => dispatch(
			updateSelectedProductConsumptionDate(productKey, deliveryDate),
		),
		amount: ({ productKey, amount }) => (dispatch) => dispatch(
			updateSelectedProductAmount(productKey, amount),
		),
	},
	[CHECK_IN_STEPS['product-meal-kit-clean-kitchen-vegan-dan-dan-noodles-2']]: {
		products: (productKeys) => (dispatch) => dispatch(updateSelectedProductKeys(productKeys)),
	},
	[CHECK_IN_STEPS['product-stocked-fridge']]: {
		products: (productKeys) => (dispatch) => dispatch(updateSelectedProductKeys(productKeys)),
	},
	[CHECK_IN_STEPS['summary']]: {
		products: (productKeys) => (dispatch) => dispatch(updateSelectedProductKeys(productKeys)),
	},
	[CHECK_IN_STEPS['add-card']]: {
		name: (name) => (dispatch) => dispatch(updateMainGuestNewCreditCard({ name })),
		cardNumber: (cardNumber) => (dispatch) => dispatch(updateMainGuestNewCreditCard({ cardNumber })),
		expirationDate: (expirationDate) => (dispatch) => dispatch(updateMainGuestNewCreditCard({ expirationDate })),
		cvv: (cvv) => (dispatch) => dispatch(updateMainGuestNewCreditCard({ cvv })),
		type: (type) => (dispatch) => dispatch(updateMainGuestNewCreditCard({ type })),
		numberMasked: (numberMasked) => (dispatch) => dispatch(updateMainGuestNewCreditCard({ numberMasked })),
		transactionId: (transactionId) => (dispatch) => dispatch(updateMainGuestNewCreditCard({ transactionId })),
	},
	[CHECK_IN_STEPS['guest-area-cleaning-mid-stay-select-time']]: {
		currentCleaningDate: (cleaningDate) => (dispatch) => dispatch(updateCurrentCleaningDate(cleaningDate)),
		currentCleaningTime: (cleaningTime) => (dispatch) => dispatch(updateCurrentCleaningTime(cleaningTime)),
	},

	[CHECK_IN_STEPS['room-upgrade']]: {
		products: (productKeys) => (dispatch) => dispatch(updateSelectedProductKeys(productKeys)),
		propertyUnitCategoryId: ({ productKey, propertyUnitCategoryId }) => (dispatch) => dispatch(
			updateSelectedProductsAdditionalData(productKey, { propertyUnitCategoryId }),
		),
	},
	[CHECK_IN_STEPS['product-property-unit-category-upgrade-free']]: {
		products: (productKeys) => (dispatch) => dispatch(updateSelectedProductKeys(productKeys)),
		propertyUnitCategoryId: ({ productKey, propertyUnitCategoryId }) => (dispatch) => dispatch(
			updateSelectedProductsAdditionalData(productKey, { propertyUnitCategoryId }),
		),
	},
	[CHECK_IN_STEPS['product-property-unit-category-upgrade']]: {
		products: (productKeys) => (dispatch) => dispatch(updateSelectedProductKeys(productKeys)),
		propertyUnitCategoryId: ({ productKey, propertyUnitCategoryId }) => (dispatch) => dispatch(
			updateSelectedProductsAdditionalData(productKey, { propertyUnitCategoryId }),
		),
	},
}

export default StepFieldUpdaters
