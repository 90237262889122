/* eslint import/no-cycle: 0 */
import ls from '../../local-storage/local-storage'
import logger from '../../logger/logger'
import { trackPageView } from '@/utils'
import { setLanguage } from 'Src/translator'
import AppActionTypes from './app.types'

import {
	selectAppState,
	selectCurrentStep,
	selectMainFlowSteps,
} from './app.selectors'

import { getStepIndex } from './app.utils'
import { patchReservation, reservationExpand } from '../reservation/reservation.actions'
import { selectIsCheckInCompleted, selectReservation } from '../reservation/reservation.selectors'
import StepFieldUpdaters from '../step-specific/step-field-updater.actions'
import getStepAPIPatchDataSelector from '../step-specific/step-api-patch-data.selectors'
import config from '../../globals/constants'

import {
	CurrentStepDispatchType,
	SetCurrentStepFormErrorsType,
	SetPCIProxyMerchantIdType,
	ShowAlertPopupType,
	StepOptions,
	UpdateProgressType,
} from './app-actions.types'

import { RootState } from '../store'
import { ReservationApi } from 'Types/api.types'
import { StepData, FLOW_SERVICE_MAP, AppError } from 'Types/app-state.types'
import getStepFormErrorsSelector from '../step-specific/step-form-errors.selectors'
import { client } from '@/api/feathers'
import { createAction } from '@reduxjs/toolkit'

const {
	IS_DEVELOPMENT,
	STEPS,
	PCI_PROXY_MERCHANT_IDS,
} = config

const updateBrowserHistory = (stepData: StepData) => {
	const { history } = window

	if (!history.state) {
		history.replaceState(stepData, '', '')
	} else if (stepData.step !== history.state.step) {
		history.pushState(stepData, '', '')
	}
}

const showAlertPopup = (content: any, callback?: () => any) => (dispatch: ShowAlertPopupType) => {
	dispatch({
		type: AppActionTypes.SET_POPUP_DATA,
		payload: {
			content,
			callback,
		},
	})
}

const showConfirmationPopup = (
	content?: any,
	onConfirm?: () => any,
	onCancel?: () => any,
	confirmButtonText?: string,
	cancelButtonText?: string,
) => ({
	type: AppActionTypes.SET_POPUP_DATA,
	payload: {
		content,
		onConfirm,
		onCancel,
		confirmButtonText,
		cancelButtonText,
	},
})

const closePopupAction = createAction(AppActionTypes.SET_POPUP_DATA)

const setSnackbarText = (text: string | null) => ({
	type: AppActionTypes.SET_SNACKBAR_TEXT,
	payload: text,
})

const setIsSubmitting = (isSubmitting: boolean) => ({
	type: AppActionTypes.SET_IS_SUBMITTING,
	payload: isSubmitting,
})

const setErrorViewVisible = (isVisible: boolean) => ({
	type: AppActionTypes.SET_ERROR_VIEW_VISIBLE,
	payload: isVisible,
})

const updateProgress = () => (dispatch: UpdateProgressType, getState: () => RootState) => {
	const currentStep = selectCurrentStep(getState())
	const mainFlowSteps = selectMainFlowSteps(getState())
	const isStepInMainFlow = mainFlowSteps.includes(currentStep)

	if (!isStepInMainFlow) return

	dispatch({
		type: AppActionTypes.UPDATE_PROGRESS,
		payload: {
			progress: getStepIndex(mainFlowSteps, currentStep),
			progressMax: mainFlowSteps.length,
		},
	})
}

const getStepDataHistory = (
	stepData: StepData,
	options: any,
	previousStepDataIndex: number | null,
	previousStepDataHistory: StepData[],
) => {
	let stepDataIndex
	let stepDataHistory
	const { step } = stepData || {}
	const {
		toPrevious,
		resetStepHistoryTip,
	} = options || {}

	if (resetStepHistoryTip) {
		const nextStepIndex = previousStepDataHistory.findIndex(({ step }) => step === stepData.step)
		previousStepDataHistory.splice(nextStepIndex === -1 ? previousStepDataHistory.length - 1 : nextStepIndex + 1)
	}

	// if really going BACK
	if (toPrevious) {
		// new ZERO
		if (previousStepDataIndex === 0) {
			stepDataIndex = 0
			stepDataHistory = [
				stepData,
				...(previousStepDataHistory || []),
			]
			// just take prev step
		} else {
			stepDataIndex = (previousStepDataIndex || 0) - 1
			stepDataHistory = [
				...(previousStepDataHistory || []),
			]
		}
		// if seems like going BACK
	} else if (
		previousStepDataHistory
		&& previousStepDataIndex
		&& previousStepDataIndex > 0
		&& previousStepDataHistory[previousStepDataIndex - 1]?.step === step
	) {
		stepDataIndex = previousStepDataIndex - 1
		stepDataHistory = [
			...(previousStepDataHistory || []),
		]
		// overwriting future
	} else if ((previousStepDataIndex || 0) + 1 < (previousStepDataHistory?.length || 0)) {
		stepDataIndex = (previousStepDataIndex || 0) + 1
		stepDataHistory = [
			...previousStepDataHistory.slice(0, stepDataIndex),
			stepData,
		]
		// adding new step
	} else {
		stepDataIndex = previousStepDataHistory?.length || 0

		if (
			stepDataIndex > 0
			&& previousStepDataHistory?.length
			&& stepData.step === previousStepDataHistory[stepDataIndex - 1].step
		) {
			stepDataIndex -= 1
			stepDataHistory = previousStepDataHistory
		} else {
			stepDataHistory = [
				...(previousStepDataHistory || []),
				stepData,
			]
		}
	}

	return {
		stepDataIndex,
		stepDataHistory,
	}
}

const setCurrentStep = (stepData: StepData, options?: StepOptions) => (
	dispatch: CurrentStepDispatchType,
	getState: () => RootState,
) => {
	const {
		flow,
		stepDataIndex: previousStepDataIndex,
		stepDataHistory: previousStepDataHistory,
	} = selectAppState(getState())

	const { stepDataIndex, stepDataHistory } = getStepDataHistory(
		stepData,
		options,
		previousStepDataIndex,
		previousStepDataHistory,
	)

	dispatch({
		type: AppActionTypes.SET_CURRENT_STEP,
		payload: {
			stepDataIndex,
			stepDataHistory,
		},
	})

	logger.datadogLogs.addLoggerGlobalContext('step', stepData?.step)
	updateBrowserHistory(stepData)
	trackPageView(flow, stepData.step)
}

const initCurrentStep = () => (dispatch: (...args: any[]) => any, getState: () => RootState) => {
	const { flow } = selectAppState(getState())
	const isCheckInCompleted = selectIsCheckInCompleted(getState())

	const currentStepInLocalStorage = ls.getItem('step')
	let currentStep

	if (isCheckInCompleted) {
		currentStep = STEPS[flow].COMPLETED['guest-area']
	} else if (currentStepInLocalStorage) {
		currentStep = STEPS[flow].MAIN[currentStepInLocalStorage] || STEPS[flow].MAIN['start']
	} else {
		currentStep = STEPS[flow].MAIN['start']
	}

	dispatch(setCurrentStep({ step: currentStep }))
	dispatch(updateProgress())
}

const setPCIProxyMerchantId = (merchantId: string | null) => (dispatch: SetPCIProxyMerchantIdType) => {
	// :todo - make local BE/crawler work with reservations from Mews, not dummy data
	if (IS_DEVELOPMENT && !merchantId) {
		merchantId = PCI_PROXY_MERCHANT_IDS.development
	}

	dispatch({
		type: AppActionTypes.SET_PCI_PROXY_MERCHANT_ID,
		payload: { merchantId },
	})
}

const initAppState = (flow: string, reservation: ReservationApi) => (dispatch: (...args: any[]) => any) => {
	dispatch(setErrorViewVisible(false))

	const savedLanguage = reservation.customer?.languageCode
	let validCode

	// set language from db if set
	if (savedLanguage) {
		validCode = setLanguage(savedLanguage)
	} else if (navigator.language) {
		validCode = setLanguage(navigator.language)
	}

	if (validCode) {
		dispatch({
			type: AppActionTypes.SWITCH_LANGUAGE,
			payload: validCode,
		})
	}

	dispatch({
		type: AppActionTypes.INIT_APP_STATE,
		payload: {
			flow,
			reservationId: reservation?.id,
		},
	})
	dispatch(initCurrentStep())
	dispatch(setPCIProxyMerchantId(reservation.property.pciProxyPublicKey))
}

const setCurrentStepFormErrors = (errors: AppError[], toScrollToTop: boolean) => (
	dispatch: SetCurrentStepFormErrorsType,
) => {
	if (toScrollToTop) {
		window.scrollTo(0, 0)
	}

	dispatch({
		type: AppActionTypes.SET_CURRENT_STEP_FORM_ERRORS,
		payload: { errors },
	})
}

const removeCurrentStepFormError = (fieldName: string) => ({
	type: AppActionTypes.REMOVE_CURRENT_STEP_FORM_ERROR,
	payload: { fieldName },
})

const patchCurrentStepChanges = (callback?: (modifiedReservation?: ReservationApi) => any) => (
	dispatch: (...args: any[]) => any,
	getState: () => RootState,
) => {
	const currentStep = selectCurrentStep(getState())
	const selectStepAPIPatchData = getStepAPIPatchDataSelector(currentStep)

	// FIXME: this selector may return wrong data, not that `patchReservation` could accept (ex.: credit card data)
	// Have no idea how and why it works at the moment
	const state = getState() as any
	const data = selectStepAPIPatchData(state) as any

	if (!data) {
		if (callback) callback()

		return
	}

	if (callback) {
		dispatch(patchReservation(data, (modifiedReservation) => callback(modifiedReservation)))
	} else {
		dispatch(patchReservation(data))
	}
}

const updateCurrentStepField = (fieldName: any, data: any, withPatch?: boolean) => (
	dispatch: (...args: any[]) => void,
	getState: () => RootState,
) => {
	const currentStep = selectCurrentStep(getState())

	dispatch(removeCurrentStepFormError(fieldName))
	// @ts-ignore
	dispatch(StepFieldUpdaters[currentStep][fieldName](data))
	dispatch(updateProgress())
	if (withPatch) dispatch(patchCurrentStepChanges())
}

const submitAndUpdateProductsStep = (data: string, productDataKey?: string, productData?: string) => (
	dispatch: (...args: any[]) => void,
	getState: () => RootState,
) => {
	const currentStep = selectCurrentStep(getState())
	const selectStepFormErrors = getStepFormErrorsSelector(currentStep)
	const formErrors = selectStepFormErrors(getState())
	if (formErrors?.length) {
		dispatch(setCurrentStepFormErrors(formErrors, true))
		return
	}
	// :todo This is to be done on the continuation of the typescript conversion (for the app reducer) in dev-475 ticket
	// @ts-ignore
	dispatch(StepFieldUpdaters[currentStep]['products'](data))
	if (productDataKey && productData) {
		// @ts-ignores
		dispatch(StepFieldUpdaters[currentStep][productDataKey]({ productKey: data[0], [productDataKey]: productData }))
	}
	dispatch(updateProgress())
	dispatch(patchCurrentStepChanges())
}

const switchLanguage = (languageCode: string) => (
	dispatch: (...args: any[]) => void,
	getState: () => RootState,
) => {
	const { slug: reservationSlug } = selectReservation(getState()) || {}

	setLanguage(languageCode)

	client
		.service(FLOW_SERVICE_MAP.CHECK_IN)
		.patch(reservationSlug, {
			customer: {
				languageCode,
			},
			query: {
				$expand: reservationExpand,
			},
		})

	return dispatch({
		type: AppActionTypes.SWITCH_LANGUAGE,
		payload: languageCode,
	})
}

export {
	initAppState,
	setCurrentStepFormErrors,
	setCurrentStep,
	updateProgress,
	updateCurrentStepField,
	submitAndUpdateProductsStep,
	patchCurrentStepChanges,
	showConfirmationPopup,
	showAlertPopup,
	closePopupAction,
	setSnackbarText,
	setIsSubmitting,
	setErrorViewVisible,
	switchLanguage,
}
