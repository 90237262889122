import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import { Button } from 'Components/ui'

import {
	Section,
	HeaderSticky,
	FooterSectionSticky,
} from 'Components/view-structure.component'

import Carousel from '../../../components/carousel.component'
import { goToStep } from '../../../redux/app/go-to-step.actions'
import { selectReservation } from '../../../redux/reservation/reservation.selectors'
import { selectLanguage } from '../../../redux/app/app.selectors'
import config, { PropertyAccessType } from '../../../globals/constants'
import { TranslationContext } from 'Src/context'
import PageView from 'Src/components/PageView'

const { PROPERTY_ADDITIONAL_DETAILS } = config

const Footer = styled(FooterSectionSticky)`
	margin: 0;
	justify-content: center;
`

const StartView = ({
	reservation: { property: { key: propertyKey } },
	goToNextStep,
}) => {
	const translation = useContext(TranslationContext)

	const carouselData = [{
		heading: translation.t('No front desk, no problem'),
		text: translation.t('There’s no reception at our properties. Instead, you share your information with us beforehand so you can go straight to your apartment'),
	}]

	// eslint-disable-next-line default-case
	switch (PROPERTY_ADDITIONAL_DETAILS[propertyKey].accessType) {
		case PropertyAccessType.Code: {
			carouselData.push({
				heading: translation.t('Enter your apartment with a digital key'),
				text: translation.t('Bob doesn’t carry around keys. Instead, you get a digital key access code once you’ve completed your booking. Super simple.'),
			})

			break
		}

		case PropertyAccessType.Remote: {
			carouselData.push({
				heading: translation.t('Enter your apartment with a digital key'),
				text: translation.t('Bob doesn’t carry around keys. Instead, you get a digital key once you’ve completed your booking. Super simple.'),
			})

			break
		}

		case PropertyAccessType.PhysicalKey: {
			carouselData.push({
				heading: translation.t('Enter your apartment with a keycard'),
				text: translation.t('Pick up your keycard from the self-service check-in kiosk upon arriving at the property. Use it to open all doors during your stay.'),
			})

			break
		}
	}

	carouselData.push({
		heading: translation.t('Special request?'),
		text: translation.t('Need early check-in, a baby crib, parking, breakfast, or something else? Complete your booking now to get hooked up.'),
	})

	carouselData.forEach((el, idx) => {
		carouselData[idx].img = PROPERTY_ADDITIONAL_DETAILS[propertyKey].carouselImages[idx]
	})

	return (
		<PageView
			Header={() => <HeaderSticky />}
			FooterSection={() => (
				<Footer>
					<Button variant="primary" onClick={goToNextStep}>
						{translation.t('Complete booking')}
					</Button>
				</Footer>
			)}
		>
			<Section>
				<Carousel data={carouselData} />
			</Section>
		</PageView>
	)
}

const mapStateToProps = createStructuredSelector({
	reservation: selectReservation,
	language: selectLanguage,
})

const mapDispatchToProps = (dispatch) => ({
	goToNextStep: () => dispatch(goToStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StartView)
