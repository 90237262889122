import React, { useState } from 'react'
import styled from 'styled-components'

import FieldLabel from './field-label.component'
import Button from './button.component'

const Label = styled(FieldLabel)`
	display: inline-block;
	margin-bottom: 3px;
`

const Wrapper = styled.div``

const ButtonsGroup = styled.div`
	display: flex;

	${Button} {
		flex: 1;

		&:first-child {
			border-radius: 2px 0 0 2px;
		}

		&:not(:first-child):not(:last-child) {
			border-radius: 0;
		}

		&:last-child {
			border-radius: 0 2px 2px 0;
		}
	}
`

const isObject = (variable) => typeof variable === 'object' && variable !== null
const isOptionSelected = (opt, value) => (isObject(value) ? value.value === opt.value : value === opt)

const RadioButtons = ({
	className,
	isValid,
	label,
	name,
	options,
	value: initialValue,
	handleChange,
}) => {
	const [value, setValue] = useState(initialValue)
	const [isFocused, setIsFocused] = useState(false)
	const alertModeOn = isValid === false

	const onButtonClick = (opt) => {
		setValue(opt)
		handleChange(opt)
	}

	return (
		<Wrapper
			className={className}
			alertModeOn={alertModeOn}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
		>
			{label ? (
				<Label htmlFor={name} alertModeOn={alertModeOn} isFocused={isFocused}>
					{label}
				</Label>
			) : null}

			<ButtonsGroup>
				{options.map((opt) => (
					<Button
						key={isObject(opt) ? opt.value : opt}
						variant={isOptionSelected(opt, value) ? 'default' : 'secondary'}
						onClick={() => onButtonClick(opt)}
					>
						{isObject(opt) ? opt.label : opt}
					</Button>
				))}
			</ButtonsGroup>

			<input id={name} hidden readOnly value={value?.value || ''} />
		</Wrapper>
	)
}

export default RadioButtons
