import React, { useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
	ContentHeader,
	FooterSection,
	HeaderSticky,
	InvalidFieldsAlertSection,
	Section,
} from 'Components/view-structure.component'
import IdentificationFields from '../../../components/guest-identification-fields.component'
import ResidenceFields from '../../../components/guest-residence-fields.component'
import MainGuestContactsFields from '../../../components/main-guest-contacts-fields.component'
import AdditionalDetailsFields from '../../../components/guest-additional-details-fields.component'

import { selectAppState, selectIsSubmitting } from 'Redux/app/app.selectors'
import { updateCurrentStepField } from 'Redux/app/app.actions'
import { goToPrevStep } from 'Redux/app/go-to-step.actions'
import { selectReservation } from 'Redux/reservation/reservation.selectors'
import {
	selectAdditionalDocumentFieldsRequired,
	selectAddressRequired,
	selectBirthPlaceAndDateRequired,
	selectMainGuest,
	selectMainGuestAddress,
	selectMainGuestBirthDateRequired,
	selectMainGuestCustomer,
	selectMainGuestDocument,
	selectMainGuestDocumentDetailsRequired,
	selectMainGuestDocumentSupportNumberRequired,
	selectMainGuestGenderRequired,
	selectMainGuestIsEmailFieldVisible,
	selectMainGuestVisitedCountryRequired,
	selectNextDestinationRequired,
	selectPostalCodeRequired,
} from 'Redux/reservation/guests/guests.selectors'

import { TranslationContext } from 'Src/context'
import { getDocumentTypes } from 'Src/utils'
import PageView from 'Src/components/PageView'
import BirthlpaceAndDate from 'Components/guest-birthplace-date.component'

const MainGuestDetailsView = ({
	appState,
	isSubmitting,
	reservation,
	guest,
	customer,
	isEmailFieldVisible,
	document,
	documentDetailsRequired,
	additionalDocumentFieldsRequired,
	documentSupportNumberRequired,
	birthPlaceAndDateRequired,
	birthDateRequired,
	isAddressRequired,
	isPostalCodeRequired,
	genderRequired,
	visitedCountryRequired,
	nextDestinationRequired,
	mainGuestAddress,
	updateField,
	goToPrevStepWithApiPatch,
	goToPrevStep,
}) => {
	const translation = useContext(TranslationContext)

	const {
		progressData,
		currentStepFormErrors: formErrors,
	} = appState

	const {
		property: {
			phone: propertyPhone,
			country: propertyCountry,
			countryCode: propertyCountryCode,
		},
	} = reservation ?? {}
	const { visitedCountryCode, destinationCountryCode, destinationCity } = guest
	const {
		firstName,
		lastName,
		birthDate,
		birthCountryCode,
		birthProvince,
		nationalityCode,
		email,
		phone,
		gender,
		phoneCountryCallingCode,
		phoneCountryCode,
	} = customer ?? {}

	const {
		type: documentType,
		number: documentNumber,
		supportNumber: documentSupportNumber,
		issueCountry: documentIssueCountry,
		issueProvince: documentIssueProvince,
	} = document ?? {}

	const {
		addressLine1,
		postalCode,
		city,
		countryCode: guestCountryCode,
		countrySubdivisionCode,
	} = mainGuestAddress ?? {}

	const documentTypes = useMemo(
		() => getDocumentTypes(propertyCountryCode, nationalityCode, translation),
		[propertyCountryCode, nationalityCode, translation],
	)

	useEffect(() => {
		if (documentTypes.length > 0 && (documentType === undefined || documentType === '')) {
			updateField('documentType', documentTypes[0].value)
		}
	}, [])

	return (
		<form
			onSubmit={(ev) => {
				ev.preventDefault()
				goToPrevStepWithApiPatch()
			}}
		>
			<PageView
				Header={() => (
					<HeaderSticky
						{...progressData}
						onBackButtonClick={goToPrevStep}
						contactButton={{ url: `tel:${propertyPhone}` }}
					/>
				)}
			>
				<ContentHeader
					mainText={translation.t('Main Guest Info')}
					subText={translation.t('Enter the details of the main guest')}
				/>

				{!!formErrors?.length && <InvalidFieldsAlertSection />}

				<Section>
					<IdentificationFields
						{...{
							heading: translation.t('Details of main guest'),
							firstName,
							lastName,
							birthDate,
							birthPlaceAndDateRequired,
							birthDateRequired,
							gender,
							genderRequired,
							nationalityCode,
							updateField,
							formErrors,
						}}
					/>
				</Section>

				{birthPlaceAndDateRequired && (
					<Section>
						<BirthlpaceAndDate
							{...{
								heading: translation.t('Birthplace and date'),
								birthDate,
								birthCountryCode,
								birthProvince,
								updateField,
								formErrors,
							}}
						/>
					</Section>
				)}

				<Section>
					<ResidenceFields
						{...{
							addressLine1,
							city,
							postalCode,
							isPostalCodeRequired,
							countryCode: guestCountryCode,
							isAddressRequired,
							isProvinceRequired: true,
							countrySubdivisionCode,
							updateField,
							formErrors,
						}}
					/>
				</Section>

				<Section>
					<MainGuestContactsFields
						{...{
							email,
							phone,
							phoneCountryCallingCode,
							phoneCountryCode,
							isEmailFieldVisible,
							updateField,
							formErrors,
						}}
					/>
				</Section>

				{(
					visitedCountryRequired
					|| documentDetailsRequired
					|| nextDestinationRequired
				) && (
					<Section>
						<AdditionalDetailsFields
							{...{
								documentType,
								documentNumber,
								documentIssueCountry,
								documentIssueProvince,
								documentSupportNumber,
								documentDetailsRequired,
								additionalDocumentFieldsRequired,
								documentSupportNumberRequired,
								visitedCountryCode,
								visitedCountryRequired,
								nextDestinationRequired,
								destinationCountryCode,
								destinationCity,
								propertyCountry,
								documentTypes,
								updateField,
								formErrors,
							}}
						/>
					</Section>
				)}

				<FooterSection
					onPrevClick={goToPrevStep}
					disableNextButton={isSubmitting}
					nextButtonText={translation.t('Update')}
					prevButtonText={translation.t('Cancel')}
				/>
			</PageView>
		</form>
	)
}

const mapStateToProps = createStructuredSelector({
	appState: selectAppState,
	reservation: selectReservation,
	guest: selectMainGuest,
	customer: selectMainGuestCustomer,
	documentDetailsRequired: selectMainGuestDocumentDetailsRequired,
	additionalDocumentFieldsRequired: selectAdditionalDocumentFieldsRequired,
	documentSupportNumberRequired: selectMainGuestDocumentSupportNumberRequired,
	birthPlaceAndDateRequired: selectBirthPlaceAndDateRequired,
	birthDateRequired: selectMainGuestBirthDateRequired,
	isAddressRequired: selectAddressRequired,
	isPostalCodeRequired: selectPostalCodeRequired,
	visitedCountryRequired: selectMainGuestVisitedCountryRequired,
	genderRequired: selectMainGuestGenderRequired,
	nextDestinationRequired: selectNextDestinationRequired,
	isEmailFieldVisible: selectMainGuestIsEmailFieldVisible,
	mainGuestAddress: selectMainGuestAddress,
	document: selectMainGuestDocument,
	isSubmitting: selectIsSubmitting,
})

const mapDispatchToProps = (dispatch) => ({
	updateField: (fieldName, data) => dispatch(updateCurrentStepField(fieldName, data)),
	goToPrevStepWithApiPatch: () => dispatch(goToPrevStep({ withApiPatch: true })),
	goToPrevStep: () => dispatch(goToPrevStep()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(MainGuestDetailsView)
